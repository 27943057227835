var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-content",
    [
      _c(
        "v-container",
        {
          staticClass: "login-bg-color",
          attrs: { fluid: "", "fill-height": "" },
        },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", md6: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-1 login-card",
                      staticStyle: { "min-width": "464px" },
                      attrs: { id: "login-card" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "login-logo",
                            attrs: { src: require("@/assets/logo_rgb.png") },
                          }),
                        ]
                      ),
                      _c("v-card-title", { staticClass: "subheading" }, [
                        _vm._v("Sign in here"),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.submit.apply(null, arguments)
                                },
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|email",
                                    expression: "'required|email'",
                                  },
                                ],
                                attrs: {
                                  name: "login",
                                  label: "Email",
                                  type: "text",
                                  "data-vv-name": "email",
                                  "error-messages": _vm.errors.collect("email"),
                                  box: "",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.submit.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  id: "password",
                                  name: "password",
                                  label: "Password",
                                  type: _vm.showPassword ? "text" : "password",
                                  box: "",
                                  "data-vv-name": "password",
                                  "data-vv-delay": "100",
                                  "data-vv-rules": "required",
                                  "error-messages":
                                    _vm.errors.first("password"),
                                  "persistent-hint": "",
                                  counter: "",
                                  "append-icon": _vm.showPassword
                                    ? "visibility"
                                    : "visibility_off",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.submit.apply(null, arguments)
                                  },
                                  "click:append": function ($event) {
                                    _vm.showPassword = !_vm.showPassword
                                  },
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.loginError
                            ? _c(
                                "div",
                                { attrs: { id: "display-error" } },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        value: _vm.loginError,
                                        transition: "fade-transition",
                                        type: "error",
                                      },
                                    },
                                    [_vm._v(" Incorrect email or password ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            { staticClass: "caption text-xs-left" },
                            [
                              _vm._v("Forgot your password? "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "login-text-links",
                                  attrs: {
                                    to: {
                                      name: "Recover Password",
                                      params: { email: _vm.email },
                                    },
                                  },
                                },
                                [_vm._v("Reset Password")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "login-button",
                                color: "secondary",
                                depressed: "",
                                loading: _vm.signInLoading,
                                disabled: _vm.signInLoading,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submit.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(" Login ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }