var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    {
      staticStyle: { margin: "2%", "margin-top": "24px" },
      attrs: { xs12: "", sm12: "", md12: "" },
    },
    [
      _c(
        "v-card",
        { staticClass: "card", attrs: { height: "100%" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-layout",
                { staticClass: "top-card-row", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "top-section",
                      attrs: { xs12: "", sm6: "", md6: "", lg8: "", xl8: "" },
                    },
                    [
                      _c("h3", { staticClass: "sent-title" }, [
                        _vm._v(_vm._s(_vm.title + " Sentiment")),
                      ]),
                      _vm.title == "Direct Message"
                        ? _c("p", { staticClass: "description" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  "These scores reprresent the sentiment of all the direct messages for a given timeframe"
                                ) +
                                " "
                            ),
                          ])
                        : _c("p", { staticClass: "description" }, [
                            _vm._v(_vm._s(_vm.description)),
                          ]),
                      _c("h5", { staticClass: "total-emails" }, [
                        _vm._v(" Total Items Evaluated "),
                        _vm.loading
                          ? _c("span", [_vm._v("--")])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.totalItems?.toLocaleString("en-US") ||
                                      "0"
                                  ) +
                                  " "
                              ),
                            ]),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", sm2: "", md2: "", lg2: "", x2: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "timeframe-container" },
                        [
                          _c("v-select", {
                            staticClass: "timeframe-select",
                            attrs: {
                              items: _vm.timeframeItems,
                              "item-text": "label",
                              "item-value": "value",
                              loading: _vm.loading,
                              dark: "",
                              "hide-details": "",
                            },
                            on: { change: _vm.getItemsBetweenTimeframe },
                            model: {
                              value: _vm.timeFrame,
                              callback: function ($$v) {
                                _vm.timeFrame = $$v
                              },
                              expression: "timeFrame",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("v-layout", { staticClass: "percentages-row" }, [
                _c(
                  "article",
                  { staticClass: "row" },
                  [
                    _c("h5", [_vm._v("Positive")]),
                    _vm.loading
                      ? _c("v-progress-circular", {
                          staticClass: "space-left",
                          attrs: {
                            size: 20,
                            color: "primary",
                            indeterminate: "",
                          },
                        })
                      : _c(
                          "h5",
                          { staticClass: "percent positive space-left" },
                          [_vm._v(" " + _vm._s(_vm.positiveScore) + " ")]
                        ),
                  ],
                  1
                ),
                _c(
                  "article",
                  { staticClass: "row" },
                  [
                    _c("h5", [_vm._v("Neutral")]),
                    _vm.loading
                      ? _c("v-progress-circular", {
                          staticClass: "space-left",
                          attrs: {
                            size: 20,
                            color: "primary",
                            indeterminate: "",
                          },
                        })
                      : _c(
                          "h5",
                          { staticClass: "percent neutral space-left" },
                          [_vm._v(" " + _vm._s(_vm.neutralScore) + " ")]
                        ),
                  ],
                  1
                ),
                _c(
                  "article",
                  { staticClass: "row" },
                  [
                    _c("h5", [_vm._v("Negative")]),
                    _vm.loading
                      ? _c("v-progress-circular", {
                          staticClass: "space-left",
                          attrs: {
                            size: 20,
                            color: "primary",
                            indeterminate: "",
                          },
                        })
                      : _c(
                          "h5",
                          { staticClass: "percent negative space-left" },
                          [_vm._v(" " + _vm._s(_vm.negativeScore) + " ")]
                        ),
                  ],
                  1
                ),
                _c("div", { staticClass: "row" }, [
                  _c("article", { staticClass: "grade-col space-left" }, [
                    _c("p", { staticClass: "grade-text" }, [
                      _vm._v("Current Grade"),
                    ]),
                    _vm.loading
                      ? _c("h1", { staticClass: "grade C" }, [_vm._v("--")])
                      : _c(
                          "h1",
                          {
                            staticClass: "grade",
                            class: _vm.gradeColor(_vm.grade),
                          },
                          [_vm._v(" " + _vm._s(_vm.grade || "--") + " ")]
                        ),
                  ]),
                  _c("article", { staticClass: "grade-col space-left" }, [
                    _c("p", { staticClass: "grade-text" }, [
                      _vm._v("Benchmark Grade"),
                    ]),
                    _vm.loading
                      ? _c("h1", { staticClass: "grade C" }, [_vm._v("--")])
                      : _c(
                          "h1",
                          {
                            staticClass: "grade",
                            class: _vm.gradeColor(_vm.benchmark),
                          },
                          [_vm._v(" " + _vm._s(_vm.benchmark) + " ")]
                        ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }