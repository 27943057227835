var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        {
          attrs: { row: "", wrap: "", "fill-height": "", "justify-center": "" },
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "", lg10: "", "mb-3": "" } },
            [
              _c(
                "v-card",
                { staticClass: "ma-3 pa-3", attrs: { flat: "" } },
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _c("div", { staticClass: "headline primary--text" }, [
                      _vm._v("Let's Confirm Everything"),
                    ]),
                  ]),
                  _c("v-card-text", [
                    _c("div", { staticClass: "subheading" }, [
                      _vm._v(" Here's a recap of what we accomplished. "),
                    ]),
                    _c("br"),
                    !_vm.onboardingCompleted && _vm.errorMessages.length > 0
                      ? _c(
                          "div",
                          { staticClass: "caption" },
                          [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  outline: "",
                                  value: true,
                                  type: "error",
                                },
                              },
                              [
                                _vm._v(
                                  "Please correct the following error(s): "
                                ),
                                _c(
                                  "ul",
                                  _vm._l(_vm.errorMessages, function (error) {
                                    return _c("li", { key: error }, [
                                      _vm._v(" " + _vm._s(error) + " "),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "ma-3 pa-3 login-card elevation-1" },
                [
                  _c("v-card-title", [_c("h5", [_vm._v("Moderators")])]),
                  _c(
                    "v-card-text",
                    [
                      _vm.moderators.length < 1
                        ? _c("p", { staticClass: "body-1" }, [
                            _vm._v(" No moderators added. "),
                          ])
                        : _c(
                            "v-list",
                            _vm._l(_vm.moderators, function (item, index) {
                              return _c(
                                "v-chip",
                                {
                                  key: item.email,
                                  attrs: {
                                    "v-model": _vm.moderators[index],
                                    color: "black",
                                    "text-color": "white",
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.email) + " ")]
                              )
                            }),
                            1
                          ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.editStep(3)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("edit")]), _vm._v(" Edit ")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "ma-3 pa-3 login-card elevation-1" },
                [
                  _c("v-card-title", [_c("h4", [_vm._v("Monitoring")])]),
                  _vm.selectedModeratorOption == "specified_users"
                    ? _c("v-card", { staticClass: "card_box" }, [
                        _c("h5", [_vm._v("Monitor Specified Users")]),
                        _c("div", { staticClass: "csv_info_text pt-2" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.fileCountsData.total_valid_mail) +
                                " User Imported "
                            ),
                          ]),
                        ]),
                      ])
                    : _c("v-card", { staticClass: "card_box" }, [
                        _c("h5", [_vm._v("Monitor All Users")]),
                        _c("div", { staticClass: "pt-2" }, [
                          _c("span", [
                            _vm._v(
                              "Subscribed to all company users for monitoring."
                            ),
                          ]),
                        ]),
                      ]),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.editStep(4)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("edit")]), _vm._v(" Edit ")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "ma-3 pa-3 login-card elevation-1" },
                [
                  _c("v-card-title", [_c("h5", [_vm._v("Excluded Emails")])]),
                  _c(
                    "v-card-text",
                    [
                      _vm.excludedList.length < 1
                        ? _c("p", { staticClass: "body-1" }, [
                            _vm._v(" No excluded emails added. "),
                          ])
                        : _c(
                            "v-list",
                            _vm._l(_vm.excludedList, function (item, index) {
                              return _c(
                                "v-chip",
                                {
                                  key: item.email,
                                  attrs: {
                                    "v-model": _vm.excludedList[index],
                                    color: "black",
                                    "text-color": "white",
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.email) + " ")]
                              )
                            }),
                            1
                          ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.editStep(5)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("edit")]), _vm._v(" Edit ")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "ma-3 pa-3 login-card elevation-1" },
                [
                  _c("v-card-title", [
                    _c("h5", [_vm._v("Contact Numbers for SMS Notifications")]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _vm.moderators.length < 1
                        ? _c(
                            "p",
                            {
                              staticClass: "body-1",
                              staticStyle: { color: "#ff5252" },
                            },
                            [_vm._v(" No moderators have been added. ")]
                          )
                        : !_vm.atLeastOnePhoneNumber
                        ? _c("p", { staticClass: "body-1" }, [
                            _vm._v(" No phone numbers have been set. "),
                          ])
                        : _c(
                            "v-list",
                            _vm._l(_vm.moderators, function (item) {
                              return _c(
                                "v-layout",
                                {
                                  key: item.email,
                                  attrs: {
                                    row: "",
                                    wrap: "",
                                    "justify-space-between": "",
                                  },
                                },
                                [
                                  item.phone_number
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm6: "",
                                            md6: "",
                                            "align-center": "",
                                          },
                                        },
                                        [
                                          item.full_name
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "word-wrap": "break-word",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(item.full_name)
                                                  ),
                                                  _c("br"),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "caption",
                                              staticStyle: {
                                                "word-wrap": "break-word",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.email) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.phone_number
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: { xs12: "", sm6: "", md6: "" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: true,
                                              filled: "",
                                              value: _vm.formatPhoneNumber(
                                                item.phone_number
                                              ),
                                              box: "",
                                              clearable: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "prepend-inner",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "icon_image_temp",
                                                        },
                                                        [
                                                          _c("v-img", {
                                                            staticClass:
                                                              "icon_img",
                                                            attrs: {
                                                              src: require("@/assets/us-flag.svg"),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.editStep(6)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("edit")]), _vm._v(" Edit ")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }