var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-snackbar",
        {
          attrs: {
            "auto-height": "",
            top: "",
            color: _vm.currentNotificationColor,
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("v-progress-circular", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showProgress,
                expression: "showProgress",
              },
            ],
            staticClass: "ma-2",
            attrs: { indeterminate: "" },
          }),
          _vm._v(_vm._s(_vm.currentNotificationContent) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.close.apply(null, arguments)
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }