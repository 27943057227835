var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", sm12: "", md12: "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("Edit Company"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        attrs: { "lazy-validation": "" },
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: { label: "Company Name", required: "" },
                          model: {
                            value: _vm.companyName,
                            callback: function ($$v) {
                              _vm.companyName = $$v
                            },
                            expression: "companyName",
                          },
                        }),
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'",
                            },
                          ],
                          attrs: {
                            label: "Admin E-mail",
                            type: "email",
                            "data-vv-name": "email",
                            "error-messages": _vm.errors.collect("email"),
                            required: "",
                          },
                          model: {
                            value: _vm.mailServerAdmin,
                            callback: function ($$v) {
                              _vm.mailServerAdmin = $$v
                            },
                            expression: "mailServerAdmin",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: { label: "Allow Domain", box: "" },
                          model: {
                            value: _vm.allowedDomains,
                            callback: function ($$v) {
                              _vm.allowedDomains = $$v
                            },
                            expression: "allowedDomains",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: { label: "Description", box: "" },
                          model: {
                            value: _vm.description,
                            callback: function ($$v) {
                              _vm.description = $$v
                            },
                            expression: "description",
                          },
                        }),
                        _c(
                          "v-container",
                          { attrs: { "grid-list-md": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label: "Gmail",
                                        color: "indigo",
                                        value: "gmail",
                                      },
                                      model: {
                                        value: _vm.gmail,
                                        callback: function ($$v) {
                                          _vm.gmail = $$v
                                        },
                                        expression: "gmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label: "Microsoft",
                                        color: "indigo",
                                        value: "microsoft",
                                      },
                                      model: {
                                        value: _vm.microsoft,
                                        callback: function ($$v) {
                                          _vm.microsoft = $$v
                                        },
                                        expression: "microsoft",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label: "Microsoft Teams",
                                        color: "indigo",
                                        value: "teams",
                                      },
                                      model: {
                                        value: _vm.teams,
                                        callback: function ($$v) {
                                          _vm.teams = $$v
                                        },
                                        expression: "teams",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.microsoft || _vm.teams
                          ? _c("v-text-field", {
                              attrs: { label: "Tenant Id", box: "" },
                              model: {
                                value: _vm.tenantId,
                                callback: function ($$v) {
                                  _vm.tenantId = $$v
                                },
                                expression: "tenantId",
                              },
                            })
                          : _vm._e(),
                        _c("v-checkbox", {
                          attrs: {
                            color: "indigo",
                            label: "Audit Only",
                            required: "",
                          },
                          model: {
                            value: _vm.auditOnly,
                            callback: function ($$v) {
                              _vm.auditOnly = $$v
                            },
                            expression: "auditOnly",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { depressed: "" }, on: { click: _vm.cancel } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.valid,
                        color: "secondary",
                        depressed: "",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Update Profile ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("Company Audit Trigger"),
                ]),
              ]),
              _c(
                "v-container",
                { attrs: { "grid-list-md": "" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.validAuditForm,
                        callback: function ($$v) {
                          _vm.validAuditForm = $$v
                        },
                        expression: "validAuditForm",
                      },
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  color: "indigo",
                                  label: "Import Users",
                                },
                                model: {
                                  value: _vm.import_users,
                                  callback: function ($$v) {
                                    _vm.import_users = $$v
                                  },
                                  expression: "import_users",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  color: "indigo",
                                  label: "Subscribe To Events",
                                },
                                model: {
                                  value: _vm.subscribe_to_events,
                                  callback: function ($$v) {
                                    _vm.subscribe_to_events = $$v
                                  },
                                  expression: "subscribe_to_events",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  color: "indigo",
                                  label: "Audit Email",
                                },
                                model: {
                                  value: _vm.audit_email,
                                  callback: function ($$v) {
                                    _vm.audit_email = $$v
                                  },
                                  expression: "audit_email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  color: "indigo",
                                  label: "Audit Teams",
                                },
                                model: {
                                  value: _vm.audit_teams,
                                  callback: function ($$v) {
                                    _vm.audit_teams = $$v
                                  },
                                  expression: "audit_teams",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  color: "indigo",
                                  label: "Audit Teams Direct Messages",
                                },
                                model: {
                                  value: _vm.audit_teams_direct_messages,
                                  callback: function ($$v) {
                                    _vm.audit_teams_direct_messages = $$v
                                  },
                                  expression: "audit_teams_direct_messages",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.audit_email ||
                      _vm.audit_teams ||
                      _vm.audit_teams_direct_messages
                        ? _c(
                            "v-flex",
                            { attrs: { xs6: "", "pr-4": "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    disabled: !_vm.isOnBoarded,
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    lazy: "",
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "min-width": "290px",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    label: "From Date",
                                                    "prepend-icon": "event",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value: _vm.fromDate,
                                                    callback: function ($$v) {
                                                      _vm.fromDate = $$v
                                                    },
                                                    expression: "fromDate",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1174340781
                                  ),
                                  model: {
                                    value: _vm.menu,
                                    callback: function ($$v) {
                                      _vm.menu = $$v
                                    },
                                    expression: "menu",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { max: _vm.currentDate },
                                    on: {
                                      input: function ($event) {
                                        _vm.menu = false
                                      },
                                    },
                                    model: {
                                      value: _vm.fromDate,
                                      callback: function ($$v) {
                                        _vm.fromDate = $$v
                                      },
                                      expression: "fromDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.audit_email ||
                      _vm.audit_teams ||
                      _vm.audit_teams_direct_messages
                        ? _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "max_value:180|min_value:1",
                                    expression: "'max_value:180|min_value:1'",
                                  },
                                ],
                                attrs: {
                                  "data-vv-name": "Number of Days",
                                  "error-messages":
                                    _vm.errors.collect("Number of Days"),
                                  label: "Number of Days",
                                },
                                model: {
                                  value: _vm.numberOfDays,
                                  callback: function ($$v) {
                                    _vm.numberOfDays = $$v
                                  },
                                  expression: "numberOfDays",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled:
                          !_vm.isOnBoarded ||
                          (!_vm.audit_email &&
                            !_vm.audit_teams &&
                            !_vm.audit_teams_direct_messages &&
                            !_vm.import_users &&
                            !_vm.subscribe_to_events) ||
                          !_vm.validAuditForm ||
                          ((_vm.audit_email ||
                            _vm.audit_teams ||
                            _vm.audit_teams_direct_messages) &&
                            _vm.numberOfDays == ""),
                        color: "secondary",
                        depressed: "",
                      },
                      on: { click: _vm.submitAudit },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }