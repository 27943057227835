var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "", "fill-height": "", "justify-center": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", md12: "", lg10: "", "mb-3": "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("Add Moderators"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "subheading" }, [
                    _c("b", [_vm._v("Moderators")]),
                    _vm._v(
                      " are individuals you want to have access to the ComSafe AI application and alerts that our system delivers when incidents are detected. "
                    ),
                  ]),
                  _c("br"),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "", md12: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { autocomplete: "off", "lazy-validation": "" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Full name",
                              type: "string",
                              rules: _vm.fullNameRule,
                              required: "",
                              box: "",
                            },
                            model: {
                              value: _vm.enteredName,
                              callback: function ($$v) {
                                _vm.enteredName = $$v
                              },
                              expression: "enteredName",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Email",
                              type: "email",
                              rules: _vm.emailRules,
                              required: "",
                              box: "",
                            },
                            model: {
                              value: _vm.enteredEmail,
                              callback: function ($$v) {
                                _vm.enteredEmail = $$v
                              },
                              expression: "enteredEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", depressed: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addModerator(_vm.selectedUser)
                                },
                              },
                            },
                            [_vm._v("Add")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("h5", [_vm._v("Moderators")]),
              _vm._l(_vm.moderators, function (item, index) {
                return _c(
                  "v-chip",
                  {
                    key: item.email,
                    attrs: {
                      "v-model": _vm.moderators[index],
                      color: "black",
                      "text-color": "white",
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(item.full_name) + " "),
                    _c(
                      "v-icon",
                      {
                        staticClass:
                          "v-chip__close v-icon material-icons theme--light",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.removeModerator(item.email)
                          },
                        },
                      },
                      [_vm._v("cancel")]
                    ),
                  ],
                  1
                )
              }),
              _vm.moderators.length < 1
                ? _c("p", { staticClass: "body-1" }, [
                    _vm._v("No moderators added."),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }