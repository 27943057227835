var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-3 pa-3" },
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _c("div", { staticClass: "headline primary--text" }, [
              _vm._v("Company Profile"),
            ]),
          ]),
          _c("v-card-text", [
            _c("div", { staticClass: "my-4" }, [
              _c("div", { staticClass: "subheading secondary--text" }, [
                _vm._v("Company Name"),
              ]),
              _vm.company && _vm.company.name
                ? _c(
                    "div",
                    { staticClass: "title primary--text text--darken-2" },
                    [_vm._v(" " + _vm._s(_vm.company.name) + " ")]
                  )
                : _c(
                    "div",
                    { staticClass: "title primary--text text--darken-2" },
                    [_vm._v("-----")]
                  ),
            ]),
            _c("div", { staticClass: "my-3" }, [
              _c("div", { staticClass: "subheading secondary--text" }, [
                _vm._v("Admin E-mail"),
              ]),
              _vm.company && _vm.company.mail_server_admin
                ? _c(
                    "div",
                    { staticClass: "title primary--text text--darken-2" },
                    [_vm._v(" " + _vm._s(_vm.company.mail_server_admin) + " ")]
                  )
                : _c(
                    "div",
                    { staticClass: "title primary--text text--darken-2" },
                    [_vm._v("-----")]
                  ),
            ]),
            _c("div", { staticClass: "my-3" }, [
              _c("div", { staticClass: "subheading secondary--text" }, [
                _vm._v("Description"),
              ]),
              _vm.company && _vm.company.description
                ? _c(
                    "div",
                    { staticClass: "title primary--text text--darken-2" },
                    [_vm._v(" " + _vm._s(_vm.company.description) + " ")]
                  )
                : _c(
                    "div",
                    { staticClass: "title primary--text text--darken-2" },
                    [_vm._v("-----")]
                  ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }