var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-list-tile",
    {
      attrs: { ripple: "" },
      on: {
        click: function ($event) {
          return _vm.navigateToMessageDetails(_vm.item.uuid)
        },
      },
    },
    [
      _c(
        "v-list-tile-action",
        [
          !_vm.item.is_read
            ? _c("v-icon", { attrs: { small: "", color: "warning" } }, [
                _vm._v("circle"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-list-tile-content",
        [
          _c("v-list-tile-title", { staticClass: "message-date" }, [
            _vm._v(_vm._s(_vm.formatTimestamp(_vm.item.created_at))),
          ]),
          _c("v-list-tile-sub-title", { staticClass: "message-subtitle" }, [
            _vm._v(_vm._s(_vm.item.message_sender)),
          ]),
          _vm.item.team_name
            ? _c("v-list-tile-sub-title", { staticClass: "message-subject" }, [
                _vm._v(" " + _vm._s(_vm.item.team_name) + " "),
              ])
            : _c("v-list-tile-sub-title", { staticClass: "message-subject" }, [
                _vm._v(" Direct Message "),
              ]),
          _c(
            "v-list-tile-sub-title",
            { staticClass: "text--primary message-text" },
            [_vm._v(_vm._s(_vm.item.text))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }