var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._v(" " + _vm._s(_vm.hasCompanyAdminAccess) + " "),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            persistent: "",
            "mini-variant": _vm.miniDrawer,
            fixed: "",
            app: "",
          },
          model: {
            value: _vm.showDrawer,
            callback: function ($$v) {
              _vm.showDrawer = $$v
            },
            expression: "showDrawer",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "fill-height": "" } },
            [
              !_vm.miniDrawer
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "nav-logo",
                        attrs: { src: require("@/assets/logo_rgb.png") },
                      }),
                    ]
                  )
                : _vm._e(),
              _c("v-divider", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      ((_vm.hasCompanyAdminAccess || _vm.hasAdminAccess) &&
                        _vm.userProfile.company_id != null) ||
                      (!_vm.hasAdminAccess && !_vm.hasCompanyAdminAccess),
                    expression:
                      "\n          ((hasCompanyAdminAccess || hasAdminAccess) &&\n            userProfile.company_id != null) ||\n          (!hasAdminAccess && !hasCompanyAdminAccess)\n        ",
                  },
                ],
              }),
              _c(
                "v-list",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        ((_vm.hasCompanyAdminAccess || _vm.hasAdminAccess) &&
                          _vm.userProfile.company_id != null) ||
                        (!_vm.hasAdminAccess && !_vm.hasCompanyAdminAccess),
                      expression:
                        "\n          ((hasCompanyAdminAccess || hasAdminAccess) &&\n            userProfile.company_id != null) ||\n          (!hasAdminAccess && !hasCompanyAdminAccess)\n        ",
                    },
                  ],
                  attrs: { subheader: "" },
                },
                [
                  _c("v-subheader", [_vm._v("Company Sentiment")]),
                  _c(
                    "v-list-tile",
                    { attrs: { to: "/main/company/sentiment" } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("business")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Sentiment")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-subheader", [_vm._v("Company Risk Potential")]),
                  _c(
                    "v-list-tile",
                    { attrs: { to: "/main/company/email" } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("email")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Email Incidents")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile",
                    {
                      class: _vm.teamsTab,
                      attrs: {
                        disabled: !_vm.teamsService,
                        to: "/main/company/teams",
                      },
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [
                          _c("v-icon", { class: _vm.teamsTab }, [
                            _vm._v("chat"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", { class: _vm.teamsTab }, [
                            _vm._v("MS Teams Incidents"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        ((_vm.hasCompanyAdminAccess || _vm.hasAdminAccess) &&
                          _vm.userProfile.company_id != null) ||
                        (!_vm.hasAdminAccess && !_vm.hasCompanyAdminAccess),
                      expression:
                        "\n          ((hasCompanyAdminAccess || hasAdminAccess) &&\n            userProfile.company_id != null) ||\n          (!hasAdminAccess && !hasCompanyAdminAccess)\n        ",
                    },
                  ],
                  attrs: { subheader: "" },
                },
                [
                  _c("v-subheader", [_vm._v("Settings")]),
                  _c(
                    "v-list-tile",
                    { attrs: { to: "/main/company/moderators" } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("badge")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Moderators")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile",
                    { attrs: { to: "/main/company/sms-notifications" } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("notifications_active")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Text Notifications"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile",
                    { attrs: { to: "/main/company/inclusion" } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("no_accounts")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Inclusion List")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile",
                    { attrs: { to: "/main/company/excluded" } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("no_accounts")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Do Not Monitor")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile",
                    { attrs: { to: "/main/company/custom-keyword-scan" } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("upload")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Monitor Keywords")])],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.hideServiceNow
                    ? _c(
                        "v-list-tile",
                        { attrs: { to: "/main/company/service-now" } },
                        [
                          _c(
                            "v-list-tile-action",
                            [_c("v-icon", [_vm._v("link")])],
                            1
                          ),
                          !_vm.verified && _vm.displayDisconnectAlert
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-list-tile-title",
                                                      _vm._g({}, on),
                                                      [
                                                        _vm._v(
                                                          " Connect to ServiceNow® "
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              id: "verification_icon",
                                                            },
                                                          },
                                                          [_vm._v("warning")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1817438667
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Your ServiceNow® account is not connected."
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v("Connect to ServiceNow®"),
                                  ]),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasAdminAccess,
                    expression: "hasAdminAccess",
                  },
                ],
              }),
              _c(
                "v-list",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasAdminAccess,
                      expression: "hasAdminAccess",
                    },
                  ],
                  attrs: { subheader: "" },
                },
                [
                  _c("v-subheader", [_vm._v("Admin")]),
                  _c(
                    "v-list-tile",
                    { attrs: { to: "/main/admin/companies/all" } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("business")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Manage Companies")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list",
                { attrs: { subheader: "" } },
                [
                  _c("v-divider"),
                  _c("v-subheader", [_vm._v("Help")]),
                  _c(
                    "v-list-tile",
                    {
                      attrs: {
                        href: "https://commsafe.ai/support-feedback/",
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("contact_support")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Support")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile",
                    {
                      attrs: {
                        href: "https://commsafe.ai/support-feedback/",
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("feedback")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Feedback")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile",
                    {
                      attrs: {
                        href: "https://commsafe.ai/support-feedback/",
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("settings_suggest")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Request A Feature"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-list",
                [
                  _c("v-divider"),
                  _c(
                    "v-list-tile",
                    { on: { click: _vm.logout } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Logout")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list-tile",
                    { on: { click: _vm.switchMiniDrawer } },
                    [
                      _c(
                        "v-list-tile-action",
                        [
                          _c("v-icon", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.miniDrawer
                                  ? "chevron_right"
                                  : "chevron_left"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Collapse")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-toolbar",
        { attrs: { dark: "", flat: "", color: "primary-lighten-4", app: "" } },
        [
          _c("v-toolbar-side-icon", {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.switchShowDrawer.apply(null, arguments)
              },
            },
          }),
          _c("v-toolbar-title", [_vm._v(_vm._s(_vm.currentRouteName))]),
          _c("v-spacer"),
          _c(
            "v-menu",
            { attrs: { bottom: "", left: "", "offset-y": "" } },
            [
              _c(
                "v-btn",
                { attrs: { slot: "activator", icon: "" }, slot: "activator" },
                [_c("v-icon", [_vm._v("more_vert")])],
                1
              ),
              _c(
                "v-list",
                { staticClass: "main-toolbar-menu" },
                [
                  _c(
                    "v-list-tile",
                    { attrs: { to: "/main/profile" } },
                    [
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Profile")])],
                        1
                      ),
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("person")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile",
                    { on: { click: _vm.logout } },
                    [
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Logout")])],
                        1
                      ),
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-content", [_c("router-view")], 1),
      _c(
        "v-footer",
        { staticClass: "pa-3", attrs: { fixed: "", app: "" } },
        [_c("v-spacer"), _c("span", [_vm._v("© " + _vm._s(_vm.appName))])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }