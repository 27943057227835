var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "", "fill-height": "", "justify-center": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", md12: "", lg10: "", "mb-3": "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-start": "",
                    "fill-height": "",
                  },
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md12: "", lg10: "", wrap: "" } },
                    [
                      _c("v-card-text", [
                        _c("div", { staticClass: "pb-3" }, [
                          _c("span", { staticClass: "pb-2" }, [
                            _c("b", [
                              _vm._v(
                                "Bulk upload of users that will not be monitored"
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "subheading pb-3" }, [
                          _vm._v(
                            " Use this option to provide a list of users that do not want to be monitored. Use our "
                          ),
                          _c("b", [_vm._v(".CSV")]),
                          _vm._v(" template and upload. "),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "upload_btn_responsive" },
                            [
                              _c("input", {
                                attrs: {
                                  id: "fileUpload",
                                  type: "file",
                                  accept: ".csv",
                                  hidden: "",
                                },
                                on: { change: _vm.onFilePicked },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: { small: "", color: "warning" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseFiles()
                                    },
                                  },
                                },
                                [_vm._v("Upload Users CSV")]
                              ),
                            ],
                            1
                          ),
                          !_vm.isCsvEvaluate
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "download_btn",
                                      attrs: { small: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadSampleCsv()
                                        },
                                      },
                                    },
                                    [_vm._v("DOWNLOAD A USER CSV TEMPLATE ")]
                                  ),
                                ],
                                1
                              )
                            : _c("div", { staticClass: "pt-4" }, [
                                _vm.fileCounts.total_invalid_mail == 0 &&
                                !_vm.isHeaderMismatched
                                  ? _c(
                                      "span",
                                      { staticClass: "csv_info_text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.fileCounts.total_valid_mail
                                            ) +
                                            " " +
                                            _vm._s(
                                              !_vm.isCsvUploaded
                                                ? "USERS WILL BE EXCLUDED"
                                                : "USERS EXCLUDED WITH " +
                                                    _vm.fileCounts
                                                      .total_invalid_mail +
                                                    " ERRORS"
                                            ) +
                                            " "
                                        ),
                                        _vm.isLoading
                                          ? _c("v-progress-circular", {
                                              staticClass: "progress_bar",
                                              attrs: { indeterminate: "" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.fileCounts.total_invalid_mail != 0 &&
                                !_vm.isHeaderMismatched
                                  ? _c(
                                      "span",
                                      { staticClass: "csv_info_text_error" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.fileCounts.total_invalid_mail
                                          ) + " ERROR'S FOUND !"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                          _vm.isHeaderMismatched
                            ? _c(
                                "span",
                                { staticClass: "csv_info_text_error" },
                                [_vm._v("FOUND INCORRECT CSV HEADER")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "upload_btn_web",
                      attrs: { md3: "", lg3: "" },
                    },
                    [
                      _c("input", {
                        attrs: {
                          id: "fileUpload",
                          type: "file",
                          accept: ".csv",
                          hidden: "",
                        },
                        on: { change: _vm.onFilePicked },
                      }),
                      !_vm.isCsvUploaded
                        ? _c(
                            "v-btn",
                            {
                              attrs: { small: "", color: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseFiles()
                                },
                              },
                            },
                            [_vm._v("UPLOAD USERS CSV")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: {
                                outline: "",
                                small: "",
                                color: "warning",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseFiles()
                                },
                              },
                            },
                            [_vm._v("UPLOAD A NEW USERS CSV")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "justify-content": "end" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        disabled: _vm.isLoading,
                        flat: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.uploadApiTriggered()
                        },
                      },
                    },
                    [_vm._v(" Upload ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "", md12: "", lg10: "", "mb-3": "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v(" Add specific users to the Do Not Monitor list. "),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "my-4" }, [
                    _c("div", { staticClass: "subheading" }, [
                      _vm._v(
                        " Add email addresses you do not want to monitor. This will stop the system from evaluating emails "
                      ),
                      _c("b", [_vm._v("send to")]),
                      _vm._v(", and "),
                      _c("b", [_vm._v("received by")]),
                      _vm._v(
                        " the email address you specify. This will work with users within your company, and users outside your company i.e external counsel or legal. "
                      ),
                    ]),
                  ]),
                  _c("br"),
                  _c("v-spacer"),
                  _c("v-flex", { attrs: { xs12: "", sm12: "", md12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "title primary--text text--darken-2" },
                      [_vm._v(" Add emails that should not be monitored ")]
                    ),
                    _c("br"),
                    _c(
                      "form",
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "email",
                              expression: "'email'",
                            },
                          ],
                          attrs: {
                            label: "Add emails that should not be monitored",
                            type: "email",
                            "data-vv-name": "email",
                            "error-messages": _vm.errors.collect("email"),
                            clearable: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.enteredEmail,
                            callback: function ($$v) {
                              _vm.enteredEmail = $$v
                            },
                            expression: "enteredEmail",
                          },
                        }),
                        _c("v-text-field", {
                          staticStyle: { display: "none" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "text-align": "right" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "secondary",
                              depressed: "",
                              disabled: !_vm.enteredEmail,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addEnteredEmail(_vm.enteredEmail)
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("h5", [_vm._v("Excluded Emails")]),
              _c("v-text-field", {
                attrs: {
                  label: "Search excluded email",
                  clearable: "",
                  outline: "",
                },
                model: {
                  value: _vm.emailSearch,
                  callback: function ($$v) {
                    _vm.emailSearch = $$v
                  },
                  expression: "emailSearch",
                },
              }),
              _vm._l(_vm.excludedList, function (item, index) {
                return _c(
                  "v-chip",
                  {
                    key: item.email,
                    attrs: {
                      "v-model": _vm.excludedList[index],
                      color: "black",
                      "text-color": "white",
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(item.email) + " "),
                    _c(
                      "v-icon",
                      {
                        staticClass:
                          "v-chip__close v-icon material-icons theme--light",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.removeExcluded(item.id)
                          },
                        },
                      },
                      [_vm._v("cancel")]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }