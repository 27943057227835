var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-card", { staticClass: "ma-3 pa-3 elevation-1 login-card" }, [
    _c("h1", { staticClass: "title" }, [_vm._v("Notification Settings")]),
    _c(
      "div",
      [
        _c("h2", { staticClass: "subheading" }, [
          _vm._v(" When should we notify you of incidents by email? "),
        ]),
        _c(
          "v-flex",
          {
            staticClass: "radio",
            attrs: { xs12: "", sm12: "", md12: "", row: "" },
          },
          [
            _c(
              "v-radio-group",
              {
                attrs: { row: "" },
                on: { change: _vm.updateNotifications },
                model: {
                  value: _vm.emailNotifications,
                  callback: function ($$v) {
                    _vm.emailNotifications = $$v
                  },
                  expression: "emailNotifications",
                },
              },
              [
                _c("v-radio", {
                  attrs: { label: "Never", value: _vm.Frequency.Never },
                }),
                _c("v-radio", {
                  attrs: {
                    label: "Immediately",
                    value: _vm.Frequency.Immediately,
                  },
                }),
                _c("v-radio", {
                  attrs: { label: "Once a day", value: _vm.Frequency.Daily },
                }),
                _c("v-radio", {
                  attrs: { label: "Once a week", value: _vm.Frequency.Weekly },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("h2", { staticClass: "subheading" }, [
          _vm._v(" When should we notify you of incidents by text message? "),
        ]),
        _c(
          "v-flex",
          {
            staticClass: "radio",
            attrs: { xs12: "", sm12: "", md12: "", row: "" },
          },
          [
            _c(
              "v-radio-group",
              {
                attrs: { row: "" },
                on: { change: _vm.updateNotifications },
                model: {
                  value: _vm.textNotifications,
                  callback: function ($$v) {
                    _vm.textNotifications = $$v
                  },
                  expression: "textNotifications",
                },
              },
              [
                _c("v-radio", {
                  attrs: { label: "Never", value: _vm.Frequency.Never },
                }),
                _c("v-radio", {
                  attrs: {
                    label: "Immediately",
                    value: _vm.Frequency.Immediately,
                  },
                }),
                _c("v-radio", {
                  attrs: { label: "Once a day", value: _vm.Frequency.Daily },
                }),
                _c("v-radio", {
                  attrs: { label: "Once a week", value: _vm.Frequency.Weekly },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }