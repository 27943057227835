var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ModeratorOptions", {
        attrs: { "upload-csv-triggered": _vm.uploadCsvTriggered },
        on: {
          fileCountsData: _vm.fileCounts,
          moderatorOption: _vm.selectedElement,
          isCsvUploaded: _vm.isCsvUploaded,
        },
      }),
      _vm.csvUploaded
        ? _c(
            "v-btn",
            { staticClass: "btn", attrs: { color: "success", flat: "" } },
            [_vm._v(" uploaded ")]
          )
        : _vm._e(),
      _vm.fileCountsData.total_valid_mail > 0 &&
      _vm.selectedModeratorOption == "specified_users" &&
      !_vm.csvUploaded
        ? _c(
            "v-btn",
            {
              staticClass: "btn",
              attrs: { color: "secondary", flat: "" },
              on: {
                click: function ($event) {
                  _vm.uploadCsvTriggered = true
                },
              },
            },
            [_vm._v(" upload ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }