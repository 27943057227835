var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { light: "", flat: "" } },
        [
          _c("v-toolbar-title", [_vm._v(" Onboarding ")]),
          _c("v-spacer"),
          _c(
            "v-menu",
            { attrs: { bottom: "", left: "", "offset-y": "" } },
            [
              _c(
                "v-btn",
                { attrs: { slot: "activator", icon: "" }, slot: "activator" },
                [_c("v-icon", [_vm._v("more_vert")])],
                1
              ),
              _c(
                "v-list",
                { staticClass: "main-toolbar-menu" },
                [
                  _c(
                    "v-list-tile",
                    { on: { click: _vm.logout } },
                    [
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Logout")])],
                        1
                      ),
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-stepper",
        {
          staticClass: "elevation-1",
          attrs: { "alt-labels": "" },
          model: {
            value: _vm.e1,
            callback: function ($$v) {
              _vm.e1 = $$v
            },
            expression: "e1",
          },
        },
        [
          _c(
            "v-stepper-header",
            { staticClass: "elevation-1" },
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.e1 > 1, step: "1", color: "green" } },
                [_vm._v(" Connect Email ")]
              ),
              _c("v-divider"),
              _vm.company.company_services.includes("teams")
                ? _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.e1 > 2,
                        step: "2",
                        color: "green",
                      },
                    },
                    [_vm._v(" Connect Chat ")]
                  )
                : _c(
                    "v-stepper-step",
                    { attrs: { step: "n/a", color: "green" } },
                    [_vm._v(" Connect Chat ")]
                  ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.moderators.length > 2 && _vm.e1 > 3,
                    step: "3",
                    color: "green",
                  },
                },
                [_vm._v(" Moderators ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.e1 > 4, step: "4", color: "green" } },
                [_vm._v(" Monitoring ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.e1 > 5, step: "5", color: "green" } },
                [_vm._v(" Excluded Emails "), _c("small", [_vm._v("Optional")])]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.e1 > 6, step: "6", color: "green" } },
                [_vm._v(" Text Notifications ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.formCompleted,
                    step: "7",
                    color: "green",
                  },
                },
                [_vm._v(" Review ")]
              ),
            ],
            1
          ),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c("GmailAuthentication", {
                    attrs: {
                      "enable-next": _vm.enableNext,
                      "verify-loading": _vm.verifyLoading,
                      company: _vm.company,
                    },
                    on: { verifyGmail: _vm.verifyGmail },
                  }),
                  _vm.company.company_services.includes("microsoft")
                    ? _c("O365Authorization", {
                        attrs: {
                          "ms-login-error": _vm.msLoginError,
                          company: _vm.company,
                          "user-profile": _vm.userProfile,
                          "o365-verification": _vm.o365Verification,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { visibility: "hidden" },
                          attrs: { color: "secondary", flat: "" },
                        },
                        [_vm._v(" Back ")]
                      ),
                      _vm.gmailOnly()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 2
                                },
                              },
                            },
                            [_vm._v(" Next ")]
                          )
                        : _vm._e(),
                      _vm.gmailTeams()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 3
                                },
                              },
                            },
                            [_vm._v(" Next ")]
                          )
                        : _vm._e(),
                      _vm.microsoftOnly()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 2
                                },
                              },
                            },
                            [_vm._v(" Next ")]
                          )
                        : _vm._e(),
                      _vm.microsoftAndTeams()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 3
                                },
                              },
                            },
                            [_vm._v(" Next ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c("MicrosoftTeamsAuthorization", {
                    attrs: {
                      "ms-login-error": _vm.msLoginError,
                      company: _vm.company,
                      "user-profile": _vm.userProfile,
                      "teams-verification": _vm.teamsVerification,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.e1 = 1
                            },
                          },
                        },
                        [_vm._v(" Back ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "secondary",
                            flat: "",
                            disabled: _vm.moderators.length < 1,
                          },
                          on: {
                            click: function ($event) {
                              _vm.e1 = 3
                            },
                          },
                        },
                        [_vm._v(" Next ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c("Moderators", {
                    attrs: { "user-profile": _vm.userProfile },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _vm.company.company_services.includes("teams")
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 2
                                },
                              },
                            },
                            [_vm._v(" Back ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 1
                                },
                              },
                            },
                            [_vm._v(" Back ")]
                          ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "secondary",
                            flat: "",
                            disabled: _vm.moderators.length < 1,
                          },
                          on: {
                            click: function ($event) {
                              _vm.e1 = 4
                            },
                          },
                        },
                        [_vm._v(" Next ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "4" } },
                [
                  _c("ModeratorOptions", {
                    attrs: { "upload-csv-triggered": _vm.uploadCsvTriggered },
                    on: {
                      fileCountsData: _vm.fileCounts,
                      moderatorOption: _vm.selectedElement,
                      isCsvUploaded: _vm.isCsvUploaded,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.e1 = 3
                            },
                          },
                        },
                        [_vm._v(" Back ")]
                      ),
                      _vm.selectedModeratorOption == "all_users" ||
                      (_vm.importUsers.user_imported &&
                        _vm.selectedModeratorOption == "specified_users" &&
                        _vm.csvUploaded)
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 5
                                },
                              },
                            },
                            [_vm._v(" Next ")]
                          )
                        : _vm._e(),
                      _vm.fileCountsData.total_valid_mail > 0 &&
                      _vm.selectedModeratorOption == "specified_users" &&
                      !_vm.csvUploaded
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.uploadCsvTriggered = true
                                },
                              },
                            },
                            [_vm._v(" Next ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "5" } },
                [
                  _c("ExcludedList", {
                    attrs: {
                      "user-profile": _vm.userProfile,
                      users: _vm.users,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.e1 = 4
                            },
                          },
                        },
                        [_vm._v(" Back ")]
                      ),
                      _vm.excludedList.length < 1
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 6
                                },
                              },
                            },
                            [_vm._v("I will do this later")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.e1 = 6
                                },
                              },
                            },
                            [_vm._v("Next")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "6" } },
                [
                  _c("ModeratorSMS", {
                    attrs: {
                      "user-profile": _vm.userProfile,
                      users: _vm.users,
                    },
                    on: { isFormValid: _vm.validForm },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.e1 = 5
                            },
                          },
                        },
                        [_vm._v(" Back ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "secondary",
                            flat: "",
                            disabled: !_vm.isFormValid,
                          },
                          on: {
                            click: function ($event) {
                              _vm.e1 = 7
                            },
                          },
                        },
                        [_vm._v("Next ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "7" } },
                [
                  _c("OnboardingReview", {
                    attrs: {
                      "user-profile": _vm.userProfile,
                      users: _vm.users,
                      "file-counts-data": _vm.fileCountsData,
                      "selected-moderator-option": _vm.selectedModeratorOption,
                    },
                    on: { "edit-step-number": _vm.edit },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.e1 = 6
                            },
                          },
                        },
                        [_vm._v(" Back ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "secondary",
                            depressed: "",
                            disabled: !_vm.onboardingCompleted,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.completeOnboarding()
                            },
                          },
                        },
                        [_vm._v(" Complete ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "8" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "ma-3 pa-3", attrs: { flat: "" } },
                    [
                      _c("v-card-title", [
                        _c("div", { staticClass: "headline primary--text" }, [
                          _vm._v("Onboarding is complete!"),
                        ]),
                        _c("br"),
                      ]),
                      _c("v-card-text", [
                        _c("p", [
                          _vm._v(
                            "You will be redirected to your Dashboard shortly."
                          ),
                        ]),
                        _c("p", { staticClass: "caption" }, [
                          _vm._v(" If you are not redirected automatically, "),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.navigateToDashboard()
                                },
                              },
                            },
                            [_vm._v("click here")]
                          ),
                          _vm._v(". "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }