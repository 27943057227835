var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [_c("ServiceNowForm", { attrs: { "user-profile": _vm.userProfile } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }