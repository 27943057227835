var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "", "fill-height": "", "justify-center": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", md12: "", lg10: "", "mb-3": "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("Text Notifications"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "subheading" }, [
                    _vm._v(" These are all of the "),
                    _c("b", [_vm._v("Moderators")]),
                    _vm._v(
                      ". To ensure Moderators respond to incidents as quickly as possible, we require at least one Moderator to have a valid phone number set to receive "
                    ),
                    _c("b", [_vm._v("Text")]),
                    _vm._v(
                      " notifications. You can update these settings at any time. "
                    ),
                  ]),
                  _c("br"),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "", md12: "" } },
                    [
                      _c("p"),
                      _vm.moderators.length < 1
                        ? _c("p", { staticClass: "body-1" }, [
                            _vm._v(
                              " There are no moderators set for your organization. "
                            ),
                          ])
                        : _vm._e(),
                      _vm.moderators.length > 0
                        ? _c(
                            "v-form",
                            { ref: "form", attrs: { autocomplete: "off" } },
                            _vm._l(_vm.moderators, function (item) {
                              return _c(
                                "v-layout",
                                {
                                  key: item.email,
                                  staticStyle: {
                                    "margin-bottom": "24px",
                                    "margin-top": "24px",
                                  },
                                  attrs: {
                                    row: "",
                                    wrap: "",
                                    "justify-space-between": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        sm6: "",
                                        md6: "",
                                        "align-center": "",
                                      },
                                    },
                                    [
                                      item.full_name
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "word-wrap": "break-word",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.full_name)
                                              ),
                                              _c("br"),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "caption",
                                          staticStyle: {
                                            "word-wrap": "break-word",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.email) + " ")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "", md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          filled: "",
                                          placeholder: "(555)555-1234",
                                          "data-vv-name": "phone_number",
                                          "data-vv-rules": "phoneNumberRule",
                                          "error-messages":
                                            _vm.errors.collect("phone_number"),
                                          prefix: "+1",
                                          rules: _vm.phoneNumberRule,
                                          counter: "10",
                                          mask: "phone",
                                          box: "",
                                          clearable: "",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.blurHandler(item)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prepend-inner",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "icon_image_temp",
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        staticClass: "icon_img",
                                                        attrs: {
                                                          src: require("@/assets/us-flag.svg"),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "append-outer",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "icon_image_temp",
                                                      style: {
                                                        visibility:
                                                          item.phone_number &&
                                                          item.phone_number !=
                                                            "" &&
                                                          _vm.phoneNumberRegex.test(
                                                            item.phone_number
                                                          )
                                                            ? "visible"
                                                            : "hidden",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "check_circle_",
                                                          attrs: {
                                                            color: "green",
                                                            right: "",
                                                          },
                                                        },
                                                        [_vm._v("check_circle")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: item.phone_number,
                                          callback: function ($$v) {
                                            _vm.$set(item, "phone_number", $$v)
                                          },
                                          expression: "item.phone_number",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }