var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.company.company_services.includes("gmail")
    ? _c(
        "v-card",
        { staticClass: "ma-3 pa-3 login-card elevation-1" },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-md": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs8: "" } },
                    [
                      _c("v-card-title", { attrs: { "primary-title": "" } }, [
                        _c("div", { staticClass: "headline primary--text" }, [
                          _vm._v("Connect Gmail Server"),
                        ]),
                      ]),
                      _c("v-card-text", [
                        _c("h5", [_vm._v("Domain-wide delegation:")]),
                        _c(
                          "ol",
                          { attrs: { type: "a" } },
                          [
                            _c("li", { staticClass: "subheading" }, [
                              _vm._v(
                                " Open Google Workspace (admin.google.com) and navigate to: Security > Access and Data Control > API Controls "
                              ),
                            ]),
                            _c("li", { staticClass: "subheading" }, [
                              _vm._v(
                                ' On this page click on "Domain Wide Delegation" '
                              ),
                            ]),
                            _c("li", { staticClass: "subheading" }, [
                              _vm._v(
                                ' Click on "Add new" > insert client ID ('
                              ),
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.company.gmail_client_id) + ") "
                                ),
                              ]),
                              _vm._v(" and add OAuth Scope "),
                            ]),
                            _c("li", { staticClass: "subheading" }, [
                              _vm._v(
                                " Leave Override control check box unchecked for: "
                              ),
                              _c("br"),
                              _c("a", [
                                _vm._v(
                                  "https://www.googleapis.com/auth/admin.directory.user"
                                ),
                              ]),
                              _c("br"),
                              _c("a", [
                                _vm._v(
                                  "https://www.googleapis.com/auth/gmail.readonly"
                                ),
                              ]),
                              _c("br"),
                              _c("a", [
                                _vm._v(
                                  "https://www.googleapis.com/auth/gmail.metadata"
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "subheading" }, [
                              _vm._v(
                                " Create a user account and give them the following access: "
                              ),
                              _c("ol", { attrs: { type: "i" } }, [
                                _c("li", { staticClass: "body-1" }, [
                                  _vm._v("User > Read"),
                                ]),
                                _c("li", { staticClass: "body-1" }, [
                                  _vm._v("Gmail > Email Log Search"),
                                ]),
                              ]),
                            ]),
                            _c("li", { staticClass: "subheading" }, [
                              _vm._v(" Set following "),
                              _c("b", [_vm._v("ADMIN API PRIVILEGES:")]),
                            ]),
                            _c("ol", { attrs: { type: "i" } }, [
                              _c("li", { staticClass: "body-1" }, [
                                _vm._v("Organization Units > Read"),
                              ]),
                              _c("li", { staticClass: "body-1" }, [
                                _vm._v("Users > Read"),
                              ]),
                              _c("li", { staticClass: "body-1" }, [
                                _vm._v("Groups > Read"),
                              ]),
                            ]),
                            _c("li", { staticClass: "subheading" }, [
                              _vm._v("Copy the newly created user here:"),
                            ]),
                            _c("v-text-field", {
                              attrs: { label: "User Id", required: "" },
                              model: {
                                value: _vm.mailServerAdmin,
                                callback: function ($$v) {
                                  _vm.mailServerAdmin = $$v
                                },
                                expression: "mailServerAdmin",
                              },
                            }),
                            _c(
                              "div",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "white--text",
                                    attrs: {
                                      color: _vm.enableNext
                                        ? "green"
                                        : "secondary",
                                      loading: _vm.verifyLoading,
                                      disabled:
                                        _vm.verifyLoading || _vm.enableNext,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.verifyGmail()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.enableNext ? "Verified" : "Verify"
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "b",
                                  {
                                    class: {
                                      "green--text": _vm.enableNext,
                                      "danger--text": !_vm.enableNext,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.enableNext
                                            ? "Connected"
                                            : "Not Connected"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs4: "", "pt-5": "", "pr-5": "" } }, [
                    _c("iframe", {
                      attrs: {
                        width: "440",
                        height: "300",
                        src: "https://www.youtube.com/embed/Bovwz7Lajh8",
                        title: "YouTube video player",
                        frameborder: "0",
                        allow:
                          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                        allowfullscreen: "",
                      },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }