var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-content",
    [
      _c(
        "v-container",
        {
          staticClass: "login-bg-color",
          attrs: { fluid: "", "fill-height": "" },
        },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", md6: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "elevation-1 login-card" },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "login-logo",
                            attrs: { src: require("@/assets/logo_rgb.png") },
                          }),
                        ]
                      ),
                      _c("v-card-title", { staticClass: "subheading" }, [
                        _vm._v("Recover Password"),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c("p", { staticClass: "body-2" }, [
                            _vm._v(
                              " A password recovery email will be sent to the registered account "
                            ),
                          ]),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.submit.apply(null, arguments)
                                },
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|email",
                                    expression: "'required|email'",
                                  },
                                ],
                                attrs: {
                                  label: "Email",
                                  placeholder: "Enter your email",
                                  type: "text",
                                  "data-vv-name": "email",
                                  "error-messages": _vm.errors.collect("email"),
                                  required: "",
                                  box: "",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.submit.apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "login-text-links",
                              attrs: { to: "/" },
                            },
                            [_vm._v("Back to Sign In")]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "submit-button",
                                disabled: !_vm.valid || _vm.email.length < 1,
                                color: "secondary",
                                depressed: "",
                                loading: _vm.loading,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submit.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(" Send Code ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }