var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", sm12: "", md12: "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("Connect to ServiceNow®"),
                ]),
              ]),
              _c("v-card-text", [
                _c("div", { staticClass: "subheading" }, [
                  _vm._v(
                    " Connect Commsafe AI detection system to your ServiceNow® Dashboard. "
                  ),
                ]),
              ]),
              _vm.isLoading
                ? _c(
                    "v-card-text",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c("br"),
                      _c("br"),
                      _c("div", [_vm._v("Loading...")]),
                      _c("br"),
                      _c("br"),
                      _c("v-progress-circular", {
                        attrs: {
                          color: "primary",
                          size: 70,
                          width: 7,
                          indeterminate: "",
                        },
                      }),
                      _c("br"),
                      _c("br"),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.serviceNowUser.company_id && !_vm.isLoading
                ? _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { autocomplete: "off", "lazy-validation": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm12: "",
                                    md10: "",
                                    lg10: "",
                                    xl10: "",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "subheading" }, [
                                    _vm._v("ServiceNow® Username"),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm10: "",
                                    md6: "",
                                    lg6: "",
                                    xl6: "",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "string",
                                      required: "",
                                      outline: "",
                                      rules: _vm.usernameRules,
                                      label: "Your ServiceNow Account username",
                                    },
                                    model: {
                                      value: _vm.snUsername,
                                      callback: function ($$v) {
                                        _vm.snUsername = $$v
                                      },
                                      expression: "snUsername",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm12: "",
                                    md10: "",
                                    lg10: "",
                                    xl10: "",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "subheading" }, [
                                    _vm._v("ServiceNow® Password"),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm10: "",
                                    md6: "",
                                    lg6: "",
                                    xl6: "",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: _vm.showPassword
                                        ? "text"
                                        : "password",
                                      autocomplete: "off",
                                      rules: _vm.passwordRules,
                                      required: "",
                                      outline: "",
                                      label: "Your ServiceNow Account password",
                                    },
                                    model: {
                                      value: _vm.snPassword,
                                      callback: function ($$v) {
                                        _vm.snPassword = $$v
                                      },
                                      expression: "snPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm12: "",
                                    md10: "",
                                    lg10: "",
                                    xl10: "",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "subheading" }, [
                                    _vm._v("URL"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._l(_vm.snDomainList, function (slot, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          sm10: "",
                                          md6: "",
                                          lg6: "",
                                          xl6: "",
                                        },
                                      },
                                      [
                                        index === 0
                                          ? _c("v-text-field", {
                                              attrs: {
                                                label: "Your custom url prefix",
                                                type: "text",
                                                outline: "",
                                                required: "",
                                                rules: _vm.domainListRules,
                                                placeholder:
                                                  "my-custom-url" + (index + 1),
                                                hint: _vm.urlPrefixHint,
                                                "persistent-hint": "",
                                                suffix: ".service-now.com",
                                              },
                                              model: {
                                                value: _vm.snDomainList[index],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.snDomainList,
                                                    index,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "snDomainList[index]",
                                              },
                                            })
                                          : _vm._e(),
                                        index !== 0
                                          ? _c("v-text-field", {
                                              attrs: {
                                                type: "text",
                                                outline: "",
                                                "append-icon": "remove",
                                                placeholder:
                                                  "my-custom-url" + (index + 1),
                                                suffix: ".service-now.com",
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  return _vm.removeSlot(index)
                                                },
                                              },
                                              model: {
                                                value: _vm.snDomainList[index],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.snDomainList,
                                                    index,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "snDomainList[index]",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { depressed: "", color: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addSlot()
                                    },
                                  },
                                },
                                [_vm._v("Add URL")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { depressed: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset("create")
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    color: "primary",
                                    disabled:
                                      _vm.submitDisabled ||
                                      _vm.snPassword.length < 1 ||
                                      _vm.snUsername.length < 1 ||
                                      _vm.snDomainList[0].length < 1,
                                    loading: _vm.submitDisabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submit("create")
                                    },
                                  },
                                },
                                [_vm._v("Save")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.serviceNowUser.company_id && !_vm.isLoading
                ? _c(
                    "v-card-text",
                    [
                      !_vm.verified && _vm.displayDisconnectAlert
                        ? _c(
                            "v-alert",
                            {
                              staticStyle: { "margin-bottom": "32px" },
                              attrs: {
                                value: true,
                                color: "warning",
                                icon: "priority_high",
                                outline: "",
                              },
                            },
                            [
                              _vm._v(
                                " Your ServiceNow® account is not connected. To allow Commsafe AI to continue sending flagged incidents to ServiceNow®, you will have to reconnect your account. "
                              ),
                            ]
                          )
                        : _c(
                            "v-alert",
                            {
                              staticStyle: { "margin-bottom": "32px" },
                              attrs: {
                                value: true,
                                color: "success",
                                icon: "check_circle",
                                outline: "",
                              },
                            },
                            [_vm._v(" Your ServiceNow® account is connected. ")]
                          ),
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { autocomplete: "off", "lazy-validation": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm12: "",
                                    md10: "",
                                    lg10: "",
                                    xl10: "",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "subheading" }, [
                                    _vm._v("ServiceNow® Username"),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm10: "",
                                    md6: "",
                                    lg6: "",
                                    xl6: "",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "text",
                                      required: "",
                                      outline: "",
                                      readonly: !_vm.editUsername,
                                      "append-icon": _vm.editUsername
                                        ? "cancel"
                                        : "edit",
                                      rules: _vm.usernameRules,
                                      label: "Your ServiceNow Account username",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        return _vm.clearUsername()
                                      },
                                    },
                                    model: {
                                      value: _vm.snUsername,
                                      callback: function ($$v) {
                                        _vm.snUsername = $$v
                                      },
                                      expression: "snUsername",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm12: "",
                                    md10: "",
                                    lg10: "",
                                    xl10: "",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "subheading" }, [
                                    _vm._v("ServiceNow® Password"),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm10: "",
                                    md6: "",
                                    lg6: "",
                                    xl6: "",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      password: "",
                                      type: "password",
                                      required: "",
                                      rules: _vm.passwordRules,
                                      outline: "",
                                      readonly: !_vm.editPassword,
                                      "append-icon": _vm.editPassword
                                        ? "cancel"
                                        : "edit",
                                      placeholder: _vm.editPassword
                                        ? ""
                                        : _vm.passwordPlaceholder,
                                      label: "Your ServiceNow Account password",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        return _vm.clearPassword()
                                      },
                                    },
                                    model: {
                                      value: _vm.snPassword,
                                      callback: function ($$v) {
                                        _vm.snPassword = $$v
                                      },
                                      expression: "snPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm12: "",
                                    md10: "",
                                    lg10: "",
                                    xl10: "",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "subheading" }, [
                                    _vm._v("URL"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._l(_vm.snDomainList, function (slot, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          sm10: "",
                                          md6: "",
                                          lg6: "",
                                          xl6: "",
                                        },
                                      },
                                      [
                                        index === 0
                                          ? _c("v-text-field", {
                                              attrs: {
                                                label: "Your custom url prefix",
                                                type: "text",
                                                outline: "",
                                                required: "",
                                                rules: _vm.domainListRules,
                                                placeholder:
                                                  "my-custom-url" + (index + 1),
                                                hint: _vm.urlPrefixHint,
                                                "persistent-hint": "",
                                                suffix: ".service-now.com",
                                              },
                                              model: {
                                                value: _vm.snDomainList[index],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.snDomainList,
                                                    index,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "snDomainList[index]",
                                              },
                                            })
                                          : _c("v-text-field", {
                                              attrs: {
                                                type: "text",
                                                outline: "",
                                                "append-icon": "remove",
                                                placeholder:
                                                  "my-custom-url" + (index + 1),
                                                suffix: ".service-now.com",
                                              },
                                              on: {
                                                "click:append": function (
                                                  $event
                                                ) {
                                                  return _vm.removeSlot(index)
                                                },
                                              },
                                              model: {
                                                value: _vm.snDomainList[index],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.snDomainList,
                                                    index,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "snDomainList[index]",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { depressed: "", color: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addSlot()
                                    },
                                  },
                                },
                                [_vm._v("Add URL")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { depressed: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset("edit")
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    color: "primary",
                                    disabled:
                                      _vm.submitDisabled ||
                                      _vm.snPassword.length < 1 ||
                                      _vm.snUsername.length < 1 ||
                                      _vm.snDomainList[0].length < 1,
                                    loading: _vm.submitDisabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submit("edit")
                                    },
                                  },
                                },
                                [_vm._v("Save")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }