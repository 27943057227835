var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", sm12: "", md12: "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("Edit User Profile"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        attrs: { "lazy-validation": "" },
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            label: "Full Name",
                            rules: _vm.fullNameRule,
                            required: "",
                            box: "",
                          },
                          model: {
                            value: _vm.fullName,
                            callback: function ($$v) {
                              _vm.fullName = $$v
                            },
                            expression: "fullName",
                          },
                        }),
                        _c("div", { staticClass: "my-3" }, [
                          _c("div", { staticClass: "subheading body-2" }, [
                            _vm._v("Email"),
                          ]),
                          _vm.userProfile && _vm.userProfile.email
                            ? _c("div", { staticClass: "text--darken-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.userProfile.email) + " "
                                ),
                              ])
                            : _c("div", { staticClass: "text--darken-2" }, [
                                _vm._v("-----"),
                              ]),
                        ]),
                        _c("div", { staticClass: "my-3" }, [
                          _c("div", { staticClass: "subheading body-2" }, [
                            _vm._v("Company"),
                          ]),
                          _vm.userProfile && _vm.userProfile.company_id
                            ? _c("div", { staticClass: "text--darken-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.company
                                        ? _vm.company.company_name
                                        : "--"
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("div", { staticClass: "text--darken-2" }, [
                                _vm._v("-----"),
                              ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { depressed: "" }, on: { click: _vm.cancel } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { depressed: "" }, on: { click: _vm.reset } },
                    [_vm._v("Reset")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.valid,
                        color: "secondary",
                        depressed: "",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }