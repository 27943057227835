var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        [
          _vm.loggedIn === null
            ? _c(
                "v-content",
                { attrs: { id: "loading" } },
                [
                  _c(
                    "v-container",
                    { attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "align-center": "", "justify-center": "" } },
                        [
                          _c("v-flex", [
                            _c(
                              "div",
                              { staticClass: "text-xs-center" },
                              [
                                _c("div", { staticClass: "headline my-5" }, [
                                  _vm._v("Loading..."),
                                ]),
                                _c("v-progress-circular", {
                                  attrs: {
                                    size: "100",
                                    indeterminate: "",
                                    color: "primary",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("router-view"),
          _c("NotificationsManager"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }