var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        {
          attrs: { row: "", wrap: "", "fill-height": "", "justify-center": "" },
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", md10: "", lg10: "", "mb-3": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card_box" },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-center": "",
                        "justify-start": "",
                        "fill-height": "",
                      },
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs2: "", sm2: "", md1: "", "pl-4": "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                row: "",
                                "data-vv-name": "selected option",
                                "error-messages":
                                  _vm.errors.collect("selected option"),
                              },
                              model: {
                                value: _vm.selectedOption,
                                callback: function ($$v) {
                                  _vm.selectedOption = $$v
                                },
                                expression: "selectedOption",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { color: "primary", value: "all_users" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs10: "", md11: "", lg11: "" } },
                        [
                          _c("v-card-text", [
                            _c("div", { staticClass: "pb-3" }, [
                              _c("span", { staticClass: "pb-2" }, [
                                _c("b", [_vm._v("Monitor All Users")]),
                              ]),
                            ]),
                            _c("div", { staticClass: "subheading" }, [
                              _vm._v(
                                " Use this option to monitor all company users. You can exclude specific users "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://app.commsafe.ai/main/company/excluded",
                                  },
                                },
                                [_vm._v("here")]
                              ),
                              _vm._v(". "),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", md10: "", lg10: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card_box" },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-center": "",
                        "justify-start": "",
                        "fill-height": "",
                      },
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs2: "", md1: "", lg1: "", "pl-4": "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                row: "",
                                "data-vv-name": "selected option",
                                "error-messages":
                                  _vm.errors.collect("selected option"),
                              },
                              model: {
                                value: _vm.selectedOption,
                                callback: function ($$v) {
                                  _vm.selectedOption = $$v
                                },
                                expression: "selectedOption",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  color: "primary",
                                  value: "specified_users",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md12: "", lg10: "", wrap: "" } },
                        [
                          _c("v-card-text", [
                            _c("div", { staticClass: "pb-3" }, [
                              _c("span", { staticClass: "pb-2" }, [
                                _c("b", [_vm._v("Monitor Specified Users")]),
                              ]),
                            ]),
                            _c("div", { staticClass: "subheading" }, [
                              _vm._v(
                                " Use this option to download a template used to bulk upload users with a "
                              ),
                              _c("b", [_vm._v(".CSV")]),
                              _vm._v(
                                " file for specific users that should be monitored. "
                              ),
                            ]),
                            _vm.selectedOption == "specified_users"
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "upload_btn_responsive" },
                                    [
                                      _c("input", {
                                        attrs: {
                                          id: "fileUpload",
                                          type: "file",
                                          accept: ".csv",
                                          hidden: "",
                                        },
                                        on: { change: _vm.onFilePicked },
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "warning",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.chooseFiles()
                                            },
                                          },
                                        },
                                        [_vm._v("Upload Users CSV")]
                                      ),
                                    ],
                                    1
                                  ),
                                  !_vm.isCsvEvaluate
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "download_btn",
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.downloadSampleCsv()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "DOWNLOAD A USER CSV TEMPLATE "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("div", { staticClass: "pt-4" }, [
                                        _vm.fileCounts.total_invalid_mail ==
                                          0 && !_vm.isHeaderMismatched
                                          ? _c(
                                              "span",
                                              { staticClass: "csv_info_text" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.fileCounts
                                                        .total_valid_mail
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      !_vm.importUsers
                                                        .user_imported ||
                                                        !_vm.isCsvUploaded
                                                        ? "USERS WILL BE MODERATED"
                                                        : "USERS IMPORTED WITH " +
                                                            _vm.fileCounts
                                                              .total_invalid_mail +
                                                            " ERRORS"
                                                    ) +
                                                    " "
                                                ),
                                                _vm.isLoading
                                                  ? _c("v-progress-circular", {
                                                      staticClass:
                                                        "progress_bar",
                                                      attrs: {
                                                        indeterminate: "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.fileCounts.total_invalid_mail !=
                                          0 && !_vm.isHeaderMismatched
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "csv_info_text_error",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.fileCounts
                                                      .total_invalid_mail
                                                  ) + " ERROR'S FOUND !"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                  _vm.isHeaderMismatched
                                    ? _c(
                                        "span",
                                        { staticClass: "csv_info_text_error" },
                                        [_vm._v("FOUND INCORRECT CSV HEADER")]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _vm.selectedOption == "specified_users"
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "upload_btn_web",
                              attrs: { md3: "", lg3: "" },
                            },
                            [
                              _c("input", {
                                attrs: {
                                  id: "fileUpload",
                                  type: "file",
                                  accept: ".csv",
                                  hidden: "",
                                },
                                on: { click: _vm.onFilePicked },
                              }),
                              !_vm.isCsvUploaded
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "", color: "warning" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.chooseFiles()
                                        },
                                      },
                                    },
                                    [_vm._v("UPLOAD USERS CSV")]
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        outline: "",
                                        small: "",
                                        color: "warning",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.chooseFiles()
                                        },
                                      },
                                    },
                                    [_vm._v("UPLOAD A NEW USERS CSV")]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }