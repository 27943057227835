var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c("article", { staticClass: "description" }, [
      _c("h3", { staticClass: "sent-title" }, [
        _vm._v("Sentiment Reflects Organizational Health"),
      ]),
      _c("p", { staticClass: "notes" }, [
        _vm._v(
          " Healthy companies, dramatically outperform their peers. This report represents insights to guide your strategy for improving the overall wellness of your company. Companies that work on their health, not only achieve measurable improvements in their organizational well-being but demonstrate tangible performance gains in as little as 6 to 12 months regardless of sectors. Manage your company health as rigorously as you do the P&Ls and the efforts will be reflected in the productivity, engagement, and retention of your people. "
        ),
      ]),
      _vm.company.company_services?.includes("teams")
        ? _c(
            "article",
            { staticClass: "data-src" },
            [
              _c("h4", { staticClass: "data-title" }, [
                _vm._v("Select Data Source"),
              ]),
              _c(
                "v-radio-group",
                {
                  attrs: { row: "" },
                  model: {
                    value: _vm.displaySentiment,
                    callback: function ($$v) {
                      _vm.displaySentiment = $$v
                    },
                    expression: "displaySentiment",
                  },
                },
                [
                  _vm.company.company_services?.includes("microsoft")
                    ? _c("v-radio", {
                        attrs: { label: "Microsoft Email", value: "msEmail" },
                      })
                    : _vm._e(),
                  _vm.company.company_services?.includes("gmail")
                    ? _c("v-radio", {
                        attrs: { label: "Gmail", value: "gmail" },
                      })
                    : _vm._e(),
                  _vm.company.company_services?.includes("teams")
                    ? _c("v-radio", {
                        attrs: {
                          label: "Microsoft Teams Chat",
                          value: "teams",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm.displaySentiment == "msEmail" || _vm.displaySentiment == "gmail"
      ? _c(
          "div",
          [
            _c("SentimentCard", {
              key: "Overall",
              attrs: {
                title: "Overall",
                description:
                  "These scores and grades represent the sentiment of all communications flowing through the selected communication channel over the selected timeframe.",
                "positive-score": _vm.toPercentage(
                  _vm.overallEmailSentiment.average_positive
                ),
                "neutral-score": _vm.toPercentage(
                  _vm.overallEmailSentiment.average_neutral
                ),
                "negative-score": _vm.toPercentage(
                  _vm.overallEmailSentiment.average_negative
                ),
                "total-items": _vm.overallEmailSentiment.total_items_evaluated,
                grade: _vm.overallEmailSentiment.grade,
                benchmark: _vm.overallEmailSentiment.benchmark,
                "timeframe-search": _vm.overallEmailSentiment.timeframe,
              },
              on: {
                fetchScores: _vm.fetchEmailOverallScores,
                setTimeframe: (timeframe) =>
                  _vm.setTimeframe(timeframe, "Overall"),
              },
            }),
            _c("SentimentCard", {
              key: "Organizational",
              attrs: {
                title: "Organizational",
                description:
                  "These scores and grades represent how employees feel and interact with one another, helping you to better understand trends in the company's culture over a selected timeframe.",
                "positive-score": _vm.toPercentage(
                  _vm.organizationalEmailSentiment.average_positive
                ),
                "neutral-score": _vm.toPercentage(
                  _vm.organizationalEmailSentiment.average_neutral
                ),
                "negative-score": _vm.toPercentage(
                  _vm.organizationalEmailSentiment.average_negative
                ),
                "total-items":
                  _vm.organizationalEmailSentiment.total_items_evaluated,
                grade: _vm.organizationalEmailSentiment.grade,
                benchmark: _vm.organizationalEmailSentiment.benchmark,
                "timeframe-search": _vm.organizationalEmailSentiment.timeframe,
              },
              on: {
                fetchScores: _vm.fetchEmailOrganizationalScores,
                setTimeframe: (timeframe) =>
                  _vm.setTimeframe(timeframe, "Organizational"),
              },
            }),
            _c("SentimentCard", {
              key: "Inbound",
              attrs: {
                title: "Inbound",
                description:
                  "These scores and grades represent how customers feel about your brand, products, and services, helping you better understand trends in the customer experience and their overall satisfaction over a selected timeframe",
                "positive-score": _vm.toPercentage(
                  _vm.inboundEmailSentiment.average_positive
                ),
                "neutral-score": _vm.toPercentage(
                  _vm.inboundEmailSentiment.average_neutral
                ),
                "negative-score": _vm.toPercentage(
                  _vm.inboundEmailSentiment.average_negative
                ),
                "total-items": _vm.inboundEmailSentiment.total_items_evaluated,
                grade: _vm.inboundEmailSentiment.grade,
                benchmark: _vm.inboundEmailSentiment.benchmark,
                "timeframe-search": _vm.inboundEmailSentiment.timeframe,
              },
              on: {
                fetchScores: _vm.fetchInboundScores,
                setTimeframe: (timeframe) =>
                  _vm.setTimeframe(timeframe, "Inbound"),
              },
            }),
            _c("SentimentCard", {
              key: "Outbound",
              attrs: {
                title: "Outbound",
                description:
                  "These scores and grades represent the sentiment employees exhibit while engaging with customers, vendors, and others outside of your company over a selected timeframe.",
                "positive-score": _vm.toPercentage(
                  _vm.outboundEmailSentiment.average_positive
                ),
                "neutral-score": _vm.toPercentage(
                  _vm.outboundEmailSentiment.average_neutral
                ),
                "negative-score": _vm.toPercentage(
                  _vm.outboundEmailSentiment.average_negative
                ),
                "total-items": _vm.outboundEmailSentiment.total_items_evaluated,
                grade: _vm.outboundEmailSentiment.grade,
                benchmark: _vm.outboundEmailSentiment.benchmark,
                "timeframe-search": _vm.outboundEmailSentiment.timeframe,
              },
              on: {
                fetchScores: _vm.fetchOutboundScores,
                setTimeframe: (timeframe) =>
                  _vm.setTimeframe(timeframe, "Outbound"),
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("SentimentCard", {
              key: "TeamsOverall",
              attrs: {
                title: "Overall",
                description:
                  "These scores and grades represent the sentiment of all communications flowing through the selected communication channel over the selected timeframe.",
                "positive-score": _vm.toPercentage(
                  _vm.overallTeamsSentiment.average_positive
                ),
                "neutral-score": _vm.toPercentage(
                  _vm.overallTeamsSentiment.average_neutral
                ),
                "negative-score": _vm.toPercentage(
                  _vm.overallTeamsSentiment.average_negative
                ),
                "total-items": _vm.overallTeamsSentiment.total_items_evaluated,
                grade: _vm.overallTeamsSentiment.grade,
                benchmark: _vm.overallTeamsSentiment.benchmark,
                "timeframe-search": _vm.overallTeamsSentiment.timeframe,
              },
              on: {
                fetchScores: _vm.fetchTeamsOverallScores,
                setTimeframe: (timeframe) =>
                  _vm.setTimeframe(timeframe, "TeamsOverall"),
              },
            }),
            _vm._l(_vm.allTeamsByTeamSentiment, function (team, index) {
              return _c("SentimentCard", {
                key: index,
                attrs: {
                  title: team.team_name,
                  description:
                    "These scores and grades represent the sentiment of all communications of this particular team over a selected timeframe.",
                  "positive-score": _vm.toPercentage(team.average_positive),
                  "neutral-score": _vm.toPercentage(team.average_neutral),
                  "negative-score": _vm.toPercentage(team.average_negative),
                  "total-items": team.total_items_evaluated,
                  grade: team.grade,
                  benchmark: team.benchmark,
                  "timeframe-search": team.timeframe,
                },
                on: {
                  fetchScores: (startDate, onDone) => {
                    _vm.fetchIndividualTeamScores(startDate, team.team_name)
                    onDone()
                  },
                  setTimeframe: (timeframe) =>
                    _vm.setTimeframe(timeframe, team.team_name),
                },
              })
            }),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }