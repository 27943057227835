var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      staticClass: "forward-dialog rounded-lg",
      staticStyle: { "border-radius": "12px" },
      attrs: {
        eager: "",
        width: "700",
        persistent: "",
        "content-class": "round",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c("v-btn", _vm._g({ staticClass: "btn" }, on), [
                _vm._v(" Forward "),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm.dialog
        ? _c(
            "v-card",
            { staticClass: "forward-card elevation-0" },
            [
              _c(
                "v-card-title",
                { attrs: { "primary-title": "" } },
                [
                  _c("h1", { staticClass: "forward-card-header" }, [
                    _vm._v("Forward Incident"),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { icon: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [_vm._v("cancel")]
                      ),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "forward-card-content" }, [
                    _vm._v(
                      "Email a copy of this incident to people who should know."
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "forward-card-content" },
                [
                  _vm.forwardList.length === 0 && _vm.enteredEmail.length === 0
                    ? _c(
                        "v-alert",
                        { attrs: { outline: "", value: true, type: "info" } },
                        [_vm._v("Enter at least one email address")]
                      )
                    : _vm._e(),
                  _vm.forwardList.length === 0 && _vm.enteredEmail.length > 0
                    ? _c("v-alert", { attrs: { value: true, type: "error" } }, [
                        _vm._v("Hit the Enter key to add a recipient"),
                      ])
                    : _vm._e(),
                  _vm.forwardList.length >= 10 && _vm.enteredEmail.length > 0
                    ? _c("v-alert", { attrs: { value: true, type: "error" } }, [
                        _vm._v("Limit to 10 email addresses"),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "my-4" }, [
                    _c("div", { staticClass: "subheader" }, [
                      _vm._v("Recipient(s):"),
                    ]),
                    _vm.forwardList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "selected-emails-container" },
                          _vm._l(_vm.forwardList, function (email, index) {
                            return _c(
                              "v-chip",
                              {
                                key: email,
                                attrs: { "v-model": _vm.forwardList[index] },
                              },
                              [
                                _vm._v(" " + _vm._s(email) + " "),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "v-chip__close v-icon material-icons theme--light",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeEmail(index)
                                      },
                                    },
                                  },
                                  [_vm._v("cancel")]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _c("div", [_vm._v("No recipients selected.")]),
                  ]),
                  _c("v-flex", { attrs: { xs12: "", sm12: "", md12: "" } }, [
                    _vm.dialog
                      ? _c(
                          "form",
                          { ref: "form", attrs: { autocomplete: "off" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  "persistent-hint": "",
                                                  hint: "Separate emails with a comma, up to 10 email addresses",
                                                  label: "Enter email",
                                                  type: "email",
                                                  rules: _vm.emailRules,
                                                  outline: "",
                                                  "append-icon":
                                                    "keyboard_return",
                                                  disabled:
                                                    _vm.forwardList.length >=
                                                    10,
                                                },
                                                on: {
                                                  keydown: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    $event.preventDefault()
                                                    return _vm.addEnteredEmail.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  "click:append": function (
                                                    $event
                                                  ) {
                                                    $event.preventDefault()
                                                    return _vm.addEnteredEmail.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.enteredEmail,
                                                  callback: function ($$v) {
                                                    _vm.enteredEmail = $$v
                                                  },
                                                  expression: "enteredEmail",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4226536336
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v("Enter up to 10 email addresses"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v(" Reset ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        depressed: "",
                        disabled: _vm.forwardList.length <= 0,
                      },
                      on: { click: _vm.submitForwards },
                    },
                    [_vm._v(" Forward ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }