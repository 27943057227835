var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", sm12: "", md12: "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("Set Password"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  [
                    _c("div", { staticClass: "my-3" }, [
                      _c("div", { staticClass: "subheading body-2" }, [
                        _vm._v("User"),
                      ]),
                      _vm.userProfile.full_name
                        ? _c("div", { staticClass: "text--darken-2" }, [
                            _vm._v(
                              " " + _vm._s(_vm.userProfile.full_name) + " "
                            ),
                          ])
                        : _c("div", { staticClass: "text--darken-2" }, [
                            _vm._v(_vm._s(_vm.userProfile.email)),
                          ]),
                    ]),
                    _c(
                      "v-form",
                      { ref: "form" },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "password",
                          attrs: {
                            type: "password",
                            label: "Current Password",
                            placeholder: "Enter your current password",
                            "data-vv-name": "password",
                            "data-vv-delay": "100",
                            "data-vv-rules": "required",
                            "error-messages": _vm.errors.first("oldPassword"),
                            box: "",
                          },
                          model: {
                            value: _vm.oldPassword,
                            callback: function ($$v) {
                              _vm.oldPassword = $$v
                            },
                            expression: "oldPassword",
                          },
                        }),
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            autocomplete: "new-password",
                            type: "password",
                            label: "New Password",
                            placeholder: "Enter your new password",
                            "data-vv-name": "password_confirmation",
                            "data-vv-delay": "100",
                            "data-vv-rules": "required|confirmed:$password",
                            "data-vv-as": "password",
                            "error-messages": _vm.errors.first("newPassword"),
                            box: "",
                            "persistent-hint": "",
                            rules: [
                              (v) =>
                                _vm.regex.test(v) ||
                                "Must be at least 8 characters in length and contain both upper and lowercase letters, and at least 1 number and special character.",
                            ],
                            hint: "Must be at least 8 characters in length and contain both upper and lowercase letters, and at least 1 number and special character.",
                            success: _vm.validatePassword(_vm.newPassword),
                            counter: "",
                          },
                          model: {
                            value: _vm.newPassword,
                            callback: function ($$v) {
                              _vm.newPassword = $$v
                            },
                            expression: "newPassword",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { depressed: "" }, on: { click: _vm.cancel } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled:
                          !_vm.valid ||
                          _vm.oldPassword.length < 1 ||
                          !_vm.validatePassword(_vm.newPassword) ||
                          _vm.loading,
                        loading: _vm.loading,
                        depressed: "",
                        color: "secondary",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("Reset Password")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }