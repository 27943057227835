var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", sm12: "", md12: "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("User Profile"),
                ]),
              ]),
              _c("v-card-text", [
                _c("div", { staticClass: "my-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "subheading body-2",
                      attrs: { "test-id": "full-name" },
                    },
                    [_vm._v("Full Name")]
                  ),
                  _vm.userProfile && _vm.userProfile.full_name
                    ? _c(
                        "div",
                        {
                          staticClass: "text--darken-2",
                          attrs: { "test-id": "user-name" },
                        },
                        [_vm._v(" " + _vm._s(_vm.userProfile.full_name) + " ")]
                      )
                    : _c("div", { staticClass: "text--darken-2" }, [
                        _vm._v("-----"),
                      ]),
                ]),
                _c("div", { staticClass: "my-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "subheading body-2",
                      attrs: { "test-id": "email" },
                    },
                    [_vm._v("Email")]
                  ),
                  _vm.userProfile && _vm.userProfile.email
                    ? _c(
                        "div",
                        {
                          staticClass: "text--darken-2",
                          attrs: { "test-id": "user-email" },
                        },
                        [_vm._v(" " + _vm._s(_vm.userProfile.email) + " ")]
                      )
                    : _c("div", { staticClass: "text--darken-2" }, [
                        _vm._v("-----"),
                      ]),
                ]),
                _c("div", { staticClass: "my-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "subheading body-2",
                      attrs: { "test-id": "company" },
                    },
                    [_vm._v("Company")]
                  ),
                  _vm.userProfile && _vm.company && _vm.company.company_name
                    ? _c(
                        "div",
                        {
                          staticClass: "text--darken-2",
                          attrs: { "test-id": "company-name" },
                        },
                        [_vm._v(" " + _vm._s(_vm.company.company_name) + " ")]
                      )
                    : _c("div", { staticClass: "text--darken-2" }, [
                        _vm._v("-----"),
                      ]),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", "test-id": "edit-button" },
                      on: { click: _vm.goToEdit },
                    },
                    [_vm._v("Edit")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", "test-id": "password-button" },
                      on: { click: _vm.goToPassword },
                    },
                    [_vm._v("Change Password")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("NotificationSettings", {
            attrs: {
              notificationFrequencyEmail:
                _vm.userProfile.notification_frequency_email,
              notificationFrequencyText:
                _vm.userProfile.notification_frequency_text,
              moderatorEmail: _vm.userProfile.email,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }