var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "", "fill-height": "", "justify-center": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", md12: "", lg10: "", "mb-3": "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-start": "",
                    "fill-height": "",
                  },
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md12: "", lg10: "", wrap: "" } },
                    [
                      _c("v-card-text", [
                        _c("div", { staticClass: "pb-3" }, [
                          _c("span", { staticClass: "pb-2" }, [
                            _c("b", [_vm._v("Monitor Keywords")]),
                          ]),
                        ]),
                        _c("div", { staticClass: "subheading pb-3" }, [
                          _vm._v(
                            " Upload a CSV file containing custom keywords and phrases that will cause the email to flagged. Limit 100 rows "
                          ),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "upload_btn_responsive" },
                            [
                              _c("input", {
                                attrs: {
                                  id: "fileUpload",
                                  type: "file",
                                  accept: ".csv",
                                  hidden: "",
                                },
                                on: { change: _vm.onFilePicked },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: { small: "", color: "warning" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseFiles()
                                    },
                                  },
                                },
                                [_vm._v("Upload Keyword CSV")]
                              ),
                            ],
                            1
                          ),
                          !_vm.isCsvEvaluate
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "download_btn",
                                      attrs: { small: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadSampleCsv()
                                        },
                                      },
                                    },
                                    [_vm._v("DOWNLOAD A KEYWORD CSV TEMPLATE ")]
                                  ),
                                ],
                                1
                              )
                            : _c("div", { staticClass: "pt-4" }, [
                                _vm.fileCounts.total_invalid_keyword == 0 &&
                                !_vm.isHeaderMismatched &&
                                _vm.fileCounts.total_valid_keyword <= 100
                                  ? _c(
                                      "span",
                                      { staticClass: "csv_info_text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.fileCounts.total_valid_keyword
                                            ) +
                                            " " +
                                            _vm._s(
                                              !_vm.isCsvUploaded
                                                ? "KEYWORDS WILL BE UPLOADED"
                                                : "KEYWORDS UPLOADED WITH " +
                                                    _vm.fileCounts
                                                      .total_invalid_keyword +
                                                    " ERRORS"
                                            ) +
                                            " "
                                        ),
                                        _vm.isLoading
                                          ? _c("v-progress-circular", {
                                              staticClass: "progress_bar",
                                              attrs: { indeterminate: "" },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.fileCounts.total_invalid_keyword != 0 &&
                                !_vm.isHeaderMismatched &&
                                _vm.fileCounts.total_valid_keyword <= 100
                                  ? _c(
                                      "span",
                                      { staticClass: "csv_info_text_error" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.fileCounts.total_invalid_keyword
                                          ) + " ERROR'S FOUND ! "
                                        ),
                                        _c("br"),
                                        _c("small", [
                                          _vm._v(
                                            "keywords must be less then 100 words"
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.fileCounts.total_valid_keyword > 100
                                  ? _c(
                                      "span",
                                      { staticClass: "csv_info_text_error" },
                                      [_vm._v(" Number of rows exceeded.")]
                                    )
                                  : _vm._e(),
                              ]),
                          _vm.isHeaderMismatched
                            ? _c(
                                "span",
                                { staticClass: "csv_info_text_error" },
                                [_vm._v("FOUND INCORRECT CSV HEADER")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "upload_btn_web",
                      attrs: { md3: "", lg3: "" },
                    },
                    [
                      _c("input", {
                        attrs: {
                          id: "fileUpload",
                          type: "file",
                          accept: ".csv",
                          hidden: "",
                        },
                        on: { change: _vm.onFilePicked },
                      }),
                      !_vm.isCsvUploaded
                        ? _c(
                            "v-btn",
                            {
                              attrs: { small: "", color: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseFiles()
                                },
                              },
                            },
                            [_vm._v("UPLOAD KEYWORD CSV")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: {
                                outline: "",
                                small: "",
                                color: "warning",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseFiles()
                                },
                              },
                            },
                            [_vm._v("UPLOAD A NEW KEYWORD CSV")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "justify-content": "end" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        disabled: _vm.isLoading,
                        flat: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.uploadApiTriggered()
                        },
                      },
                    },
                    [_vm._v(" Upload ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "", md12: "", lg10: "", "mb-3": "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("Add Keyword"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "subheading" }, [
                    _vm._v(
                      " Add keywords and phrases that will cause the email to flagged "
                    ),
                  ]),
                  _c("br"),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "", md12: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { autocomplete: "off", "lazy-validation": "" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Add Keyword",
                              type: "string",
                              rules: _vm.fullNameRule,
                              required: "",
                              box: "",
                            },
                            model: {
                              value: _vm.enteredKeyword,
                              callback: function ($$v) {
                                _vm.enteredKeyword = $$v
                              },
                              expression: "enteredKeyword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", depressed: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addKeyword()
                                },
                              },
                            },
                            [_vm._v("Add")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("h5", [_vm._v("Keywords")]),
              _vm._l(_vm.keywordsList, function (item, index) {
                return _c(
                  "v-chip",
                  {
                    key: item.uuid,
                    attrs: {
                      "v-model": _vm.keywordsList[index],
                      color: "black",
                      "text-color": "white",
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(item.keyword) + " "),
                    _c(
                      "v-icon",
                      {
                        staticClass:
                          "v-chip__close v-icon material-icons theme--light",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.removeKeyword(item.uuid)
                          },
                        },
                      },
                      [_vm._v("cancel")]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }