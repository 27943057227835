var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", sm12: "", md12: "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("Add Moderator"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        attrs: { "lazy-validate": "" },
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Full Name",
                            type: "string",
                            "data-vv-name": "fullName",
                            "error-messages": _vm.errors.collect("fullName"),
                            required: "",
                            box: "",
                          },
                          model: {
                            value: _vm.fullName,
                            callback: function ($$v) {
                              _vm.fullName = $$v
                            },
                            expression: "fullName",
                          },
                        }),
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'",
                            },
                          ],
                          attrs: {
                            label: "E-mail",
                            type: "email",
                            "data-vv-name": "email",
                            "error-messages": _vm.errors.collect("email"),
                            required: "",
                            box: "",
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            items: _vm.companies,
                            label: "Company",
                            "item-text": "company_name",
                            "item-value": "company_id",
                            box: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.updateSelectedCompany(_vm.companyId)
                            },
                          },
                          model: {
                            value: _vm.companyId,
                            callback: function ($$v) {
                              _vm.companyId = $$v
                            },
                            expression: "companyId",
                          },
                        }),
                        _vm.selectedCompany.company_name &&
                        _vm.selectedCompany.company_name.includes("CommSafe")
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  { staticClass: "subheading body-2" },
                                  [
                                    _vm._v(" User is a superuser "),
                                    _vm.isSuperuser
                                      ? _c("span", [
                                          _vm._v("(currently is a superuser)"),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "(currently is not a superuser)"
                                          ),
                                        ]),
                                  ]
                                ),
                                _c("v-checkbox", {
                                  attrs: {
                                    label: "Is superuser",
                                    color: "secondary",
                                  },
                                  model: {
                                    value: _vm.isSuperuser,
                                    callback: function ($$v) {
                                      _vm.isSuperuser = $$v
                                    },
                                    expression: "isSuperuser",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { flat: "", to: { name: "Manage Companies" } } },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        disabled: !_vm.valid,
                        depressed: "",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Create User ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }