var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-md": "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            {
              staticStyle: { "margin-top": "24px", "margin-bottom": "24px" },
              attrs: { row: "", wrap: "" },
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md12: "" } },
                [
                  _c("v-card", { staticClass: "elevation-1 stats-card" }, [
                    _c(
                      "div",
                      { staticClass: "cards-row" },
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              sm3: "",
                              md3: "",
                              "align-items-start": "",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              { staticClass: "elevation-0 stats-card-header" },
                              [
                                _c(
                                  "div",
                                  { attrs: { "align-self-start": "" } },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "stats-card-header-text" },
                                      [_vm._v("Incidents Found")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm3: "", md3: "" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "elevation-0 stats-card-child background--primary-lighten-5",
                              },
                              [
                                !_vm.companyCountsSummary
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary",
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "metrics-caption text--light",
                                      },
                                      [_vm._v("Today")]
                                    ),
                                    _c("br"),
                                    _vm.companyCountsSummary &&
                                    !_vm.countsSummaryError
                                      ? _c(
                                          "h3",
                                          {
                                            staticClass: "text--light metrics",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.companyCountsSummary
                                                    .todaysCount
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "v-text text--light",
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [_vm._v(" Data not available ")]
                                        ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm3: "", md3: "" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "elevation-0 stats-card-child background--primary-lighten-4",
                              },
                              [
                                !_vm.companyCountsSummary
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "secondary",
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "metrics-caption text--light",
                                      },
                                      [_vm._v("This Week")]
                                    ),
                                    _c("br"),
                                    _vm.companyCountsSummary &&
                                    !_vm.countsSummaryError
                                      ? _c(
                                          "h3",
                                          {
                                            staticClass: "text--light metrics",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.companyCountsSummary
                                                    .weeklyCount
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "v-text text--light",
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [_vm._v(" Data not available ")]
                                        ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm3: "", md3: "" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "elevation-0 stats-card-child background--primary-lighten-2",
                              },
                              [
                                !_vm.companyCountsSummary
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "secondary",
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "metrics-caption text--light",
                                      },
                                      [_vm._v("All Time")]
                                    ),
                                    _c("br"),
                                    _vm.companyCountsSummary &&
                                    !_vm.countsSummaryError
                                      ? _c(
                                          "h3",
                                          {
                                            staticClass: "text--light metrics",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.companyCountsSummary
                                                    .allTimeCount
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "v-text text--light",
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [_vm._v(" Data not available ")]
                                        ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticStyle: { "margin-top": "24px" },
                  attrs: { xs12: "", sm12: "", md12: "" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-1 login-card incidents-card",
                      attrs: { height: "100%" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "incidents-header",
                          attrs: { row: "", wrap: "" },
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm6: "",
                                md6: "",
                                lg8: "",
                                xl8: "",
                              },
                            },
                            [_c("h3", [_vm._v("Review Incidents")])]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs3: "",
                                sm2: "",
                                md2: "",
                                lg2: "",
                                xl2: "",
                                "align-self-end": "",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "button-container" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        id: "refresh-button",
                                        color: "secondary",
                                        depressed: "",
                                      },
                                      on: {
                                        click: _vm.updateIncidentsAndCounts,
                                      },
                                    },
                                    [_vm._v(" Refresh ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs9: "",
                                sm4: "",
                                md4: "",
                                lg2: "",
                                xl2: "",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "timeframe-container" },
                                [
                                  _c("v-select", {
                                    staticClass: "timeframe-select",
                                    attrs: {
                                      items: _vm.timeframeItems,
                                      "item-text": "label",
                                      "item-value": "value",
                                      dark: "",
                                      "hide-details": "",
                                    },
                                    on: { change: _vm.refreshIncidents },
                                    model: {
                                      value: _vm.companyDashSearch.timeframe,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.companyDashSearch,
                                          "timeframe",
                                          $$v
                                        )
                                      },
                                      expression: "companyDashSearch.timeframe",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "search-row" },
                        [
                          _c("v-text-field", {
                            staticClass: "search-bar",
                            attrs: { label: "Search" },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.searchItems.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                          _c("v-icon", { on: { click: _vm.searchItems } }, [
                            _vm._v("search"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "incidents-tabs",
                              attrs: { grow: "", "hide-slider": "" },
                              model: {
                                value: _vm.currentTab.text,
                                callback: function ($$v) {
                                  _vm.$set(_vm.currentTab, "text", $$v)
                                },
                                expression: "currentTab.text",
                              },
                            },
                            _vm._l(_vm.tabs, function (tab) {
                              return _c(
                                "v-tab",
                                {
                                  key: tab.text + tab.id,
                                  class: {
                                    "incidents-tabs--active":
                                      tab.text === _vm.currentTab.text,
                                    "incidents-tabs--inactive":
                                      tab.text !== _vm.currentTab.text,
                                  },
                                  attrs: {
                                    ripple: false,
                                    href: "#" + tab.text,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeTab(tab)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(tab.title) + " - "),
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "3px" } },
                                    [_vm._v(_vm._s(tab.count))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            [
                              _c(
                                "v-tab-item",
                                { attrs: { lazy: "" } },
                                [
                                  _vm.messagesLoading
                                    ? _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "p",
                                            {
                                              attrs: { id: "loading-message" },
                                            },
                                            [
                                              _vm._v(
                                                "Checking for new messages..."
                                              ),
                                            ]
                                          ),
                                          _c("v-progress-linear", {
                                            attrs: { indeterminate: "" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.messagesError
                                    ? _c("div", [_vm._v("An error occurred.")])
                                    : !_vm.messagesLoading &&
                                      _vm.selectedMessages.length < 1
                                    ? _c(
                                        "div",
                                        { staticClass: "incidents-container" },
                                        [
                                          _c("div", { staticClass: "robot" }, [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "img-overlay-text",
                                              },
                                              [_vm._v("Nothing To See Here!")]
                                            ),
                                            _c("img", {
                                              staticClass: "clear",
                                              attrs: {
                                                src: require("@/assets/clear.png"),
                                              },
                                            }),
                                          ]),
                                        ]
                                      )
                                    : _c(
                                        "v-list",
                                        {
                                          staticClass: "incidents-list",
                                          attrs: { "two-line": "" },
                                        },
                                        [
                                          _vm._l(
                                            _vm.paginator(
                                              _vm.selectedMessages,
                                              _vm.pagination.current,
                                              _vm.pagination.perPageItems
                                            ),
                                            function (item, index) {
                                              return [
                                                _c("IncidentPreview", {
                                                  key: item.uuid,
                                                  attrs: {
                                                    item: item,
                                                    index: index,
                                                  },
                                                }),
                                                index + 1 <
                                                _vm.selectedMessages.length
                                                  ? _c("v-divider", {
                                                      key: index,
                                                    })
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pagination",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.changePage()
                                                },
                                              },
                                            },
                                            [
                                              _vm.totalPages > 1
                                                ? _c("v-pagination", {
                                                    attrs: {
                                                      length: _vm.totalPages,
                                                      "total-visible":
                                                        _vm.totalVisiblePaginator,
                                                      flat: "",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.refreshIncidents,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.pagination.current,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.pagination,
                                                          "current",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "pagination.current",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }