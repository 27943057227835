var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { light: "", flat: "" } },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "secondary",
                depressed: "",
                to: "/main/admin/users/create",
              },
            },
            [_vm._v("Add Moderator")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                color: "secondary",
                depressed: "",
                to: "/main/admin/companies/create",
              },
            },
            [_vm._v("Create Company")]
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: { headers: _vm.headers, items: _vm.companies },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function (props) {
              return [
                _c("td", [_vm._v(_vm._s(props.item.company_name))]),
                _c("td", [_vm._v(_vm._s(props.item.mail_server_admin))]),
                _c("td", [_vm._v(_vm._s(props.item.company_description))]),
                _c(
                  "td",
                  { staticClass: "justify-center layout px-0" },
                  [
                    _c(
                      "v-tooltip",
                      { attrs: { top: "" } },
                      [
                        _c("span", [_vm._v("Edit")]),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              slot: "activator",
                              flat: "",
                              to: {
                                name: "Edit Companies",
                                params: { id: props.item.company_id },
                              },
                            },
                            slot: "activator",
                          },
                          [_c("v-icon", [_vm._v("edit")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }