var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", sm12: "", md12: "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-3 pa-3 elevation-1 login-card" },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", { staticClass: "headline primary--text" }, [
                  _vm._v("Create Company"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        attrs: { "lazy-validation": "" },
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Company Name",
                            "data-vv-name": "company service",
                            "error-messages":
                              _vm.errors.collect("company name"),
                            required: "",
                            box: "",
                          },
                          model: {
                            value: _vm.companyName,
                            callback: function ($$v) {
                              _vm.companyName = $$v
                            },
                            expression: "companyName",
                          },
                        }),
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'",
                            },
                          ],
                          attrs: {
                            label: "Admin E-mail",
                            type: "email",
                            "data-vv-name": "mailServerAdmin",
                            "error-messages":
                              _vm.errors.collect("mailServerAdmin"),
                            required: "",
                            box: "",
                          },
                          model: {
                            value: _vm.mailServerAdmin,
                            callback: function ($$v) {
                              _vm.mailServerAdmin = $$v
                            },
                            expression: "mailServerAdmin",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: { label: "Allow Domain", box: "" },
                          model: {
                            value: _vm.allowedDomains,
                            callback: function ($$v) {
                              _vm.allowedDomains = $$v
                            },
                            expression: "allowedDomains",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: { label: "Description", box: "" },
                          model: {
                            value: _vm.description,
                            callback: function ($$v) {
                              _vm.description = $$v
                            },
                            expression: "description",
                          },
                        }),
                        _c(
                          "v-container",
                          { attrs: { "grid-list-md": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      row: "",
                                      "data-vv-name": "selected service",
                                      "error-messages":
                                        _vm.errors.collect("selected service"),
                                    },
                                    model: {
                                      value: _vm.selectedService,
                                      callback: function ($$v) {
                                        _vm.selectedService = $$v
                                      },
                                      expression: "selectedService",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "" } },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            label: "Gmail",
                                            color: "indigo",
                                            value: "gmail",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "" } },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            label: "Microsoft",
                                            color: "indigo",
                                            value: "microsoft",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.selectedService == "microsoft"
                          ? _c("v-text-field", {
                              attrs: { label: "Tenant Id", box: "" },
                              model: {
                                value: _vm.tenantId,
                                callback: function ($$v) {
                                  _vm.tenantId = $$v
                                },
                                expression: "tenantId",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("v-checkbox", {
                      attrs: {
                        color: "primary",
                        label: " Create Company Admin Account",
                        disabled: true,
                      },
                      model: {
                        value: _vm.companyAdminForm,
                        callback: function ($$v) {
                          _vm.companyAdminForm = $$v
                        },
                        expression: "companyAdminForm",
                      },
                    }),
                    _vm.companyAdminForm
                      ? _c(
                          "v-form",
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email",
                                  expression: "'required|email'",
                                },
                              ],
                              attrs: {
                                label: "Moderator Email",
                                type: "email",
                                "data-vv-name": "email",
                                "error-messages": _vm.errors.collect("email"),
                                required: "",
                                box: "",
                              },
                              model: {
                                value: _vm.moderatorEmail,
                                callback: function ($$v) {
                                  _vm.moderatorEmail = $$v
                                },
                                expression: "moderatorEmail",
                              },
                            }),
                            _c("v-text-field", {
                              attrs: {
                                label: "Moderator name",
                                type: "string",
                                required: "",
                                box: "",
                              },
                              model: {
                                value: _vm.moderatorName,
                                callback: function ($$v) {
                                  _vm.moderatorName = $$v
                                },
                                expression: "moderatorName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { depressed: "" }, on: { click: _vm.cancel } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { depressed: "" }, on: { click: _vm.reset } },
                    [_vm._v("Reset")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        depressed: "",
                        disabled: !_vm.valid,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Create Company ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }