var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "ma-3 pa-3 login-card auth-tile elevation-1" },
    [
      _c("v-card-title", { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "headline primary--text" }, [
          _vm._v("Connect Microsoft Teams Server"),
        ]),
      ]),
      _c("v-card-text", [
        _c("p", { staticClass: "subheading" }, [
          _vm._v(" Connecting "),
          _c("b", [_vm._v("CommSafe AI")]),
          _vm._v(
            " to your company Microsoft Teams server allows us to import staff members to analyze communications. "
          ),
        ]),
        _c("p", { staticClass: "body-1" }, [
          _vm._v("You will be redirected to Microsoft's login page."),
        ]),
        _vm.msLoginError && !_vm.teamsVerification
          ? _c(
              "div",
              { attrs: { id: "display-error" } },
              [
                _c(
                  "v-alert",
                  {
                    attrs: {
                      value: _vm.msLoginError && !_vm.teamsVerification,
                      transition: "fade-transition",
                      type: "error",
                    },
                  },
                  [
                    _vm._v(
                      " Not able to login with Microsoft. Please try again. "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.teamsVerification
          ? _c(
              "div",
              { attrs: { id: "display-success" } },
              [
                _c(
                  "v-alert",
                  {
                    attrs: {
                      value: _vm.teamsVerification,
                      transition: "fade-transition",
                      type: "success",
                    },
                  },
                  [_vm._v(" Connected with Microsoft Teams ")]
                ),
              ],
              1
            )
          : _vm._e(),
        !_vm.teamsVerification
          ? _c("p", { staticStyle: { "text-align": "center" } }, [
              _c("img", {
                staticStyle: { cursor: "pointer" },
                attrs: {
                  src: require("@/assets/ms-symbollockup_signin_light.png"),
                },
                on: { click: _vm.teamsAdminConsent },
              }),
            ])
          : _vm._e(),
        _c("p", { staticClass: "body-1" }, [
          _vm._v(
            " To connect your company's Microsoft Teams server, you'll need to authenticate as a \"Microsoft Role\" user and enable CommSafe AI the correct permissions. "
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }