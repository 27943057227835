var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", sm12: "", md12: "" } },
        [
          _vm.loading
            ? _c(
                "v-card",
                { staticClass: "ma-3 pa-3 elevation-1 login-card" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("p", { attrs: { id: "loading-message" } }, [
                        _vm._v("Loading message details..."),
                      ]),
                      _c("v-progress-linear", { attrs: { indeterminate: "" } }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              staticClass: "ma-3 pa-3 elevation-1 login-card",
            },
            [
              _c(
                "div",
                { staticClass: "text-xs-right" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "back-btn",
                      attrs: { round: "", color: "indigo", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.back()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "", left: "" } }, [
                        _vm._v("arrow_back"),
                      ]),
                      _vm._v("Back"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _vm.itemDetail?.email_created_at
                  ? _c(
                      "div",
                      {
                        staticClass: "subheader",
                        staticStyle: {
                          "font-weight": "700",
                          display: "flex",
                          "font-size": "14px !important",
                        },
                      },
                      [
                        _vm.itemDetail?.is_read === false
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "status-icon",
                                attrs: { small: "", color: "warning" },
                              },
                              [_vm._v("circle")]
                            )
                          : _vm.itemDetail?.is_user_flagged
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "status-icon",
                                attrs: { small: "", color: "error" },
                              },
                              [_vm._v("circle")]
                            )
                          : _vm.itemDetail?.is_archived
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "status-icon",
                                attrs: { small: "", color: "success" },
                              },
                              [_vm._v("circle")]
                            )
                          : _c(
                              "v-icon",
                              {
                                staticClass: "status-icon",
                                attrs: { small: "" },
                              },
                              [_vm._v("circle")]
                            ),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatTimestamp(
                                _vm.itemDetail?.email_created_at
                              )
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "my-4" }, [
                  _c("div", { staticClass: "subheader" }, [_vm._v("Sender:")]),
                  _vm.itemDetail?.email_sender
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "word-wrap": "break-word",
                            display: "flex",
                            "flex-wrap": "wrap",
                          },
                        },
                        [
                          _c("span", { staticClass: "email-chip" }, [
                            _vm._v(_vm._s(_vm.itemDetail.email_sender)),
                          ]),
                        ]
                      )
                    : _c("span", [_vm._v("-----")]),
                ]),
                _c("div", { staticClass: "my-4" }, [
                  _c("div", { staticClass: "subheader" }, [
                    _vm._v("Recipient:"),
                  ]),
                  _vm.itemDetail?.email_recipients
                    ? _c(
                        "div",
                        { staticStyle: { "word-wrap": "break-word" } },
                        _vm._l(
                          _vm.itemDetail.email_recipients,
                          function (email, index) {
                            return _c(
                              "span",
                              { key: email, staticClass: "email-chip" },
                              [
                                _vm._v(" " + _vm._s(email) + " "),
                                index !==
                                _vm.itemDetail.email_recipients.length - 1
                                  ? _c("span")
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _c("span", [_vm._v("-----")]),
                ]),
                _c("div", { staticClass: "my-4" }, [
                  _c("div", { staticClass: "subheader" }, [_vm._v("Subject:")]),
                  _vm.itemDetail?.subject
                    ? _c("div", { staticClass: "message-details-text" }, [
                        _vm._v(" " + _vm._s(_vm.itemDetail.subject) + " "),
                      ])
                    : _c("div", { staticClass: "message-details-text" }, [
                        _vm._v("(no subject)"),
                      ]),
                ]),
                _c("div", { staticClass: "my-4" }, [
                  _c("div", { staticClass: "subheader" }, [_vm._v("Message:")]),
                  _vm.itemDetail?.body
                    ? _c("div", {
                        staticClass: "message-details-text",
                        attrs: { id: "htm" },
                      })
                    : _c(
                        "div",
                        { staticClass: "title primary--text text--darken-2" },
                        [_vm._v("-----")]
                      ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "space-between" },
                [
                  _c("v-spacer"),
                  _c(
                    "div",
                    { staticClass: "buttons-container" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.ignore()
                            },
                          },
                        },
                        [_vm._v(" Add to do not monitor ")]
                      ),
                      _vm.itemDetail?.is_archived
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn",
                              attrs: { loading: _vm.reviewLoading },
                              on: {
                                click: function ($event) {
                                  return _vm.returnToReview(_vm.itemDetail.uuid)
                                },
                              },
                            },
                            [_vm._v(" Return to Review ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "btn",
                              attrs: {
                                loading: _vm.archivedLoading,
                                disabled: _vm.itemDetail?.is_archived,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitUpdateItemStatus(
                                    _vm.itemDetail.uuid,
                                    "archived"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Archive ")]
                          ),
                      _c("ForwardEmailForm", {
                        attrs: {
                          "user-profile": _vm.userProfile,
                          "item-detail": _vm.itemDetail,
                        },
                      }),
                      _vm.itemDetail?.is_user_flagged
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn",
                              attrs: { loading: _vm.reviewLoading },
                              on: {
                                click: function ($event) {
                                  return _vm.returnToReview(_vm.itemDetail.uuid)
                                },
                              },
                            },
                            [_vm._v(" Return to Review ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "btn",
                              attrs: {
                                color: "error",
                                loading: _vm.flaggedLoading,
                                disabled: _vm.itemDetail?.is_user_flagged,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitUpdateItemStatus(
                                    _vm.itemDetail.uuid,
                                    "flagged"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Flag This ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.forwards.length > 0
                ? _c(
                    "v-card",
                    {
                      staticClass: "forward-list-card login-card",
                      attrs: { elevation: "0" },
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "subheader",
                          staticStyle: { "padding-bottom": "8px" },
                        },
                        [_vm._v("Forwards")]
                      ),
                      _vm._l(_vm.forwards, function (forward) {
                        return _c(
                          "v-card-text",
                          {
                            key: forward.id,
                            staticStyle: { "padding-top": "0" },
                          },
                          [
                            forward && forward.recipients
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: { "word-wrap": "break-word" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "forward-date",
                                        staticStyle: { "margin-right": "16px" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatTimestamp(
                                                forward.created_at
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "forward-subheader" },
                                          [_vm._v("Sent to: ")]
                                        ),
                                        _vm._l(
                                          forward.recipients,
                                          function (email) {
                                            return _c(
                                              "span",
                                              {
                                                key: email,
                                                staticClass:
                                                  "email-chip forward-email-chip",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(email) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "forward-subheader" },
                                        [_vm._v("Sent by: ")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "email-chip forward-email-chip",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(forward.sender) + " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }